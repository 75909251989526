var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "row my-4" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.keyword,
                expression: "keyword",
              },
            ],
            staticClass: "search form-control",
            attrs: {
              type: "text",
              placeholder: "Zoek hier door de producten.",
            },
            domProps: { value: _vm.keyword },
            on: {
              keyup: _vm.runSearch,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.keyword = $event.target.value
              },
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-12" }, [
        _c("div", { staticClass: "table-responsive" }, [
          _c("table", { staticClass: "table table-striped" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.results, function (product, index) {
                return index < _vm.page * _vm.itemsPerPage &&
                  index > (_vm.page - 1) * _vm.itemsPerPage - 1
                  ? _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "image-preview",
                          staticStyle: {
                            "margin-left": "0",
                            "margin-right": "0",
                          },
                        },
                        [
                          _c("span", {
                            style:
                              "width: 50px; height: 50px;background-size:cover;  background-image: url('" +
                              product.featured_image +
                              "');",
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(product.brand)),
                        _c("br"),
                        _vm._v(_vm._s(product.name)),
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(product.sku))]),
                      _vm._v(" "),
                      _c("td", [
                        _c("a", { attrs: { href: "" } }),
                        _vm._v(
                          _vm._s(
                            product.category
                              ? product.category.name
                              : "Geen categorie"
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v("€ " + _vm._s(product.price))]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(product.in_promo == 1 ? "JA" : "NEE")),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "/administrator/producten/detail/" + product.id,
                            },
                          },
                          [
                            _c("img", {
                              attrs: { src: "/img/edit-tools.png", alt: "" },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "toggle-delete-modal ml-3",
                            attrs: {
                              href: "#",
                              "data-toggle": "modal",
                              "data-target":
                                "#toggle-delete-modal-" + product.id,
                            },
                          },
                          [
                            _c("img", {
                              attrs: { src: "/img/delete-tools.png", alt: "" },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "modal fade text-left",
                            attrs: {
                              id: "toggle-delete-modal-" + product.id,
                              tabindex: "-1",
                              role: "dialog",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "modal-dialog modal-dialog-centered",
                                attrs: { role: "document" },
                              },
                              [
                                _c("div", { staticClass: "modal-content" }, [
                                  _vm._m(1, true),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "modal-body" }, [
                                    _c("p", [
                                      _vm._v("Ben je zeker dat je "),
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(product.brand) +
                                            " " +
                                            _vm._s(product.name)
                                        ),
                                      ]),
                                      _vm._v(" wil verwijderen?"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "modal-footer" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "t2",
                                        attrs: {
                                          type: "button",
                                          "data-dismiss": "modal",
                                        },
                                      },
                                      [_vm._v("Annuleren")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "t1",
                                        attrs: {
                                          href:
                                            "/administrator/producten/delete/" +
                                            product.id,
                                        },
                                      },
                                      [_vm._v("Verwijderen")]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e()
              }),
              0
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.results != []
        ? _c("div", { staticClass: "row justify-content-center" }, [
            _c("div", { staticClass: "col-auto" }, [
              _c(
                "ul",
                { staticClass: "pagination" },
                [
                  _vm.pageCount > 1 && _vm.page > 1
                    ? _c(
                        "li",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.toPage(_vm.page - 1)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        <\n                    "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.page > 6 && _vm.pageCount > 10
                    ? _c("li", [
                        _vm._v(
                          "\n                        ...\n                    "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.pageCount, function (pageNumber) {
                    return (pageNumber > _vm.page - 6 &&
                      pageNumber < _vm.page + 6) ||
                      (pageNumber > _vm.page - 6 && pageNumber < 11) ||
                      (pageNumber < _vm.page + 6 &&
                        pageNumber > _vm.pageCount - 10)
                      ? _c(
                          "li",
                          {
                            key: pageNumber,
                            class: pageNumber === _vm.page ? "active-page" : "",
                            on: {
                              click: function ($event) {
                                return _vm.toPage(pageNumber)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(pageNumber) +
                                "\n                    "
                            ),
                          ]
                        )
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  _vm.page < _vm.pageCount - 5 && _vm.pageCount > 10
                    ? _c("li", [
                        _vm._v(
                          "\n                        ...\n                    "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pageCount > 1 && _vm.page < _vm.pageCount
                    ? _c(
                        "li",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.toPage(_vm.page + 1)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        >\n                    "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("th"),
      _vm._v(" "),
      _c("th", [_vm._v("Artikel")]),
      _vm._v(" "),
      _c("th", [_vm._v("Artikelnummer")]),
      _vm._v(" "),
      _c("th", [_vm._v("Categorie")]),
      _vm._v(" "),
      _c("th", [_vm._v("Prijs")]),
      _vm._v(" "),
      _c("th", [_vm._v("Promo?")]),
      _vm._v(" "),
      _c("th", { staticStyle: { width: "90px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Verwijderen?")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }