var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-grow-1" },
    [
      _c("search-bar"),
      _vm._v(" "),
      _c("div", { staticClass: "container py-5 flex-grow-1" }, [
        _vm._m(0),
        _vm._v(" "),
        _vm.cartCount == 0
          ? _c("div", { staticClass: "row mt-4 justify-content-between" }, [
              _vm._m(1),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.cartCount > 0
          ? _c("div", { staticClass: "row mt-4 justify-content-between" }, [
              _c(
                "div",
                { staticClass: "col-lg-7" },
                [
                  _c(
                    "transition-group",
                    {
                      attrs: { name: "staggered-fade", tag: "div", css: false },
                      on: {
                        "before-enter": _vm.beforeEnter,
                        enter: _vm.enter,
                        leave: _vm.leave,
                      },
                    },
                    _vm._l(_vm.cart, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: item.id,
                          staticClass: "row",
                          attrs: { "data-index": index },
                        },
                        [
                          _c("div", { staticClass: "col-12 cart-item" }, [
                            _c("div", { staticClass: "block" }, [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-4" }, [
                                  _c("div", { staticClass: "img-container" }, [
                                    _c("img", {
                                      staticClass: "w-100",
                                      attrs: {
                                        src: item.product.featured_image,
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-8" }, [
                                  _c("div", { staticClass: "row h-100" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-12 align-self-start",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row justify-content-between",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-8" },
                                              [
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(item.product.brand)
                                                  ),
                                                ]),
                                                _c("br"),
                                                _vm._v(
                                                  "\n                                                        " +
                                                    _vm._s(item.product.name)
                                                ),
                                                _c("br"),
                                                _vm._v(" "),
                                                _c("strong", [
                                                  _vm._v(
                                                    "€ " +
                                                      _vm._s(
                                                        _vm.formatPrice(
                                                          item.product
                                                            .private_customer_price
                                                        )
                                                      )
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "col-4 text-right",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "form-group" },
                                                  [
                                                    _c("input", {
                                                      staticClass:
                                                        "text-center product-qty-input",
                                                      attrs: {
                                                        type: "number",
                                                        "data-index":
                                                          item.product.id,
                                                        min: "1",
                                                        step: "1",
                                                      },
                                                      domProps: {
                                                        value: item.qty,
                                                      },
                                                      on: {
                                                        change: _vm.changeQty,
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-12 align-self-end" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row justify-content-between",
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "col-8" },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "remove-from-cart text-uppercase",
                                                    attrs: { href: "#" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.removeFromCart(
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Verwijder item")]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "col-4 text-right",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "item-price" },
                                                  [
                                                    _vm._v(
                                                      "\n                                                            € " +
                                                        _vm._s(
                                                          _vm.formatPrice(
                                                            item.product
                                                              .private_customer_price *
                                                              item.qty
                                                          )
                                                        ) +
                                                        "\n                                                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-lg-5" }, [
                _c("div", { staticClass: "border" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 totals" }, [
                      _c(
                        "div",
                        { staticClass: "row justify-content-between" },
                        [
                          _vm._m(2),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 pb-2" }, [
                            _c(
                              "div",
                              { staticClass: "row justify-content-between" },
                              [
                                _c("div", { staticClass: "col-auto py-2" }, [
                                  _vm._v(
                                    "\n                                            Subtotaal\n                                        "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-auto py-2 cart-subtotal",
                                    attrs: { id: "cart-subtotal" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                            € " +
                                        _vm._s(
                                          _vm.formatPrice(
                                            _vm.cartTotals.subtotal
                                          )
                                        ) +
                                        "\n                                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12 totals border-top" }, [
                      _c(
                        "div",
                        { staticClass: "row justify-content-between" },
                        [
                          _c(
                            "div",
                            { staticClass: "col-auto font-weight-bold py-4" },
                            [
                              _vm._v(
                                "\n                                    Totaalprijs (incl. BTW)\n                                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-auto font-weight-bold py-4 cart-total",
                              attrs: { id: "cart-total" },
                            },
                            [
                              _vm._v(
                                "\n                                    € " +
                                  _vm._s(
                                    _vm.formatPrice(_vm.cartTotals.total)
                                  ) +
                                  "\n                                "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row no-gutters" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "site-btn w-100 text-center",
                            attrs: { to: { name: "checkout" } },
                          },
                          [_vm._v("Bestelling afronden")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h1", [
          _vm._v("\n                    Winkelmand\n                "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 text-center" }, [
      _vm._v(
        "\n                Er zitten momenteel geen items in je winkelmandje."
      ),
      _c("br"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "col-12 font-weight-bold text-uppercase py-4" },
      [_c("h5", { staticClass: "font-weight-bold" }, [_vm._v("Totalen")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }