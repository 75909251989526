var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "nav",
      {
        staticClass: "navbar navbar-expand-md navbar-light bg-white shadow-sm",
      },
      [
        _c("div", { staticClass: "container-fluid" }, [
          _c("a", { staticClass: "navbar-brand", attrs: { href: "/" } }, [
            _c("img", {
              attrs: {
                src: _vm.asset("img/Logo-Kanters_bruin.png"),
                alt: "Kanters Haar- en Nagelstyling",
              },
            }),
          ]),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "collapse navbar-collapse",
              attrs: { id: "navbarSupportedContent" },
            },
            [
              _c("ul", { staticClass: "navbar-nav mr-auto" }),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "navbar-nav ml-auto" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-item",
                      attrs: { to: { name: "home" }, tag: "li" },
                    },
                    [
                      _c(
                        "a",
                        { staticClass: "nav-link", attrs: { href: "#" } },
                        [_vm._v("Home")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "nav-item",
                      attrs: { to: "/checkout", tag: "li" },
                    },
                    [
                      _c(
                        "a",
                        { staticClass: "nav-link", attrs: { href: "" } },
                        [_vm._v("Checkout")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "#navbarSupportedContent",
          "aria-controls": "navbarSupportedContent",
          "aria-expanded": "false",
          "aria-label": "",
        },
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { attrs: { id: "admin-subheader" } }, [
      _c("div", { staticClass: "container h-100" }, [
        _c("div", { staticClass: "row align-items-center h-100" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h1", [
              _vm._v("Dealer"),
              _c("br"),
              _c("span", { staticClass: "ml-5" }, [_vm._v("Bestelplatform")]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }