<template>
    <div>

        <div class="row mt-5">
            <div class="col-12">
                <div class="form-group">
                    <input type="text" v-model="keyword" placeholder="Zoek hier je producten op merk, naam of omschrijving." @keyup="runSearch">
                </div>

            </div>
        </div>

        <div class="row mt-5">
            <div class="col-6 mb-4" v-if="results.length > 0">
                pagina: {{ page }}
            </div>
            <div class="col-6 text-right mb-4" v-if="results.length > 0">
                Aantal resultaten: {{ results.length }}
            </div>
            <div class="col-12">
                <transition-group name="list" tag="div">
                    <div v-for="(product, index) in results " class="mb-4" :key="product.id">
                        <div class="product-list-item" v-if="index < (page * itemsPerPage ) && index > ((page-1)*itemsPerPage-1)">
                            <div class="row">
                                <div class="col-2 text-center" >
                                    <div class="h-100 w-100  d-flex align-items-center justify-content-center" style="background-color:#ffffff;">
                                    <img :src="product.featured_image ? product.featured_image : '/storage/no-image.png'" :alt="product.name" style="max-width: 100%;
        max-height: 150px;">
                                    </div>
                                </div>
                                <div class="col-10 p-3">
                                    <div class="row h-100 align-items-center">
                                        <div class="col py-4">
                                            <strong>{{ product.brand }} {{ product.name }}</strong>
                                            <div class="mt-2">
                                                {{ product.short_description }}
                                            </div>

                                        </div>

                                        <div class="col-auto price" v-if="product.price == product.dealer_price">
                                            &euro;{{ formatPrice(product.price) }}
                                        </div>
                                        <div class="col-auto price" v-if="product.price != product.dealer_price">
                                            <div class="old-price" style="text-decoration: line-through; font-size: 16px; color: #695446"> &euro;{{ formatPrice(product.price) }}</div>
                                            &euro;{{ formatPrice(product.dealer_price) }}
                                        </div>
                                        <div class="col-auto pr-5 form-group">
                                            <form action="/winkelmand/toevoegen" method="post">
                                                <input type="hidden" name="id" :value="product.id">
                                                <div class="form-group qty-group">
                                                    <label for="qty">Aantal</label>
                                                    <span class="d-inline-block qty-down">-</span>
                                                    <input type="number" id="qty" min="1" step="1" value="1" name="qty"  class="form-control qty-field d-inline-block" style="height: 43px; text-align: center">
                                                    <span class="px-3 d-inline-block qty-up" >+</span>
                                                </div>
                                                <button type="submit" class="site-btn w-100" > <img :src="asset('/storage/cart-icon.png')" alt=""></button>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </transition-group>
            </div>
        </div>
        <div class="row justify-content-center" v-if="results != []">

            <div class="col-auto">
                <ul class="pagination">
                    <li v-if="pageCount > 1 && page > 1" @click="toPage(page -1)">
                        <
                    </li>
                    <li v-if="page > 6 && pageCount > 10">
                        ...
                    </li>
                    <li v-for="pageNumber in pageCount" :key="pageNumber"
                        v-if="(pageNumber > (page-6)
                            && (pageNumber < (page+6))
                            || (pageNumber > (page-6) && pageNumber < 11)
                            || (pageNumber< (page+6) && pageNumber > pageCount - 10))"
                        :class="(pageNumber === page) ? 'active-page' : ''" @click="toPage(pageNumber )">
                        {{ pageNumber  }}
                    </li>
                    <li v-if="page < (pageCount -5 ) && pageCount > 10">
                        ...
                    </li>
                    <li v-if="pageCount > 1 && page < pageCount" @click="toPage(page +1)">
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data ()  {
        return {
            products: [],
            results: [],
            resultCount: 0,
            pageCount: 0,
            keyword: this.$route.query.search,
            page: (this.$route.query.page) ? parseInt(this.$route.query.page) : 1,
            itemsPerPage: 10


        }
    },
    async mounted() {

        //this.$store.dispatch("allProductsFromDatabase");
        await this.$http.get("/api/products")

            .then((response)=>{
                //context.commit("products",response.data.products) //categories will be run from mutation
                this.products = response.data.products;
                this.results = this.products.slice(0,10);
                this.resultCount = 10;
                if (this.keyword) {
                    this.$search(this.keyword, this.products, {keys: ['name', 'brand', 'keywords'],}).then(result => {
                        this.results = result;
                        this.resultCount = result.length;
                        this.pageCount = Math.ceil(this.resultCount/this.itemsPerPage)
                    })
                }
            })

            .catch(()=>{

                console.log("Error........")

            })
        t

    },
    methods: {
        runSearch () {

                //this.$router.push({ query: { ...this.$route.query, search: this.keyword } });
                this.UpdateQueryString('search', this.keyword)

            this.$search(this.keyword, this.products, {keys: ['name', 'brand','keywords' ], 'threshold': 0.4}).then(result => {
                this.results = result
                this.resultCount = result.length;
                this.pageCount = Math.ceil(this.resultCount/this.itemsPerPage)
                this.toPage(1);

            })
        },
        initialSearch () {

                this.$search(this.keyword, this.products, {keys: ['name', 'brand','keywords' ],}).then(result => {
                    this.results = result
                    console.log(result)
                    this.resultCount = this.results.length;
                })


        },
        toPage(pageNumber) {
            this.page = pageNumber;
            //this.$router.push({ query: { ...this.$route.query, page: this.page } });
            this.UpdateQueryString('page', this.page)
            window.scrollTo(0,0);
        },
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        UpdateQueryString(key, value) {
            var queryParams = new URLSearchParams(window.location.search);
            queryParams.set(key, value);
            history.replaceState(null, null, "?"+queryParams.toString());
        },
        AddToCart(id) {
            this.$http.put("/api/add-to-cart", {id: id})
        }
    },
    computed: {
       /* getAllProducts(){ //final output from here
            return this.$store.getters.getProductsFormGetters;
        },*/

    },
    created: function() {
        this.initialSearch()
    }


}
</script>

<style lang="scss">
.product-list-item {
    background-color: rgba(#f6f6f6, .4);
    border: 1px solid #d0d0d0;
    font: {
        family: Raleway;
        size: 18px;
        weight: 400;
    }


    .price {
        font-size: 24px;
        font-weight: 700;
        color: #849e1e;
    }
    a.btn {
        padding: 10px 15px;
        border-radius: 5px;
        background-color: #849e1e;
        color: #ffffff;
    }
}
.list-enter-active, .list-leave-active {
    transition: opacity .5s;
}
.list-enter, .list-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.pagination {
    display: inline-block;
}

.pagination li {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid #ffffff;
    transition: all 300ms ease;
    &:hover:not(.active-page) {
        background-color: #f6f6f6;
        border: 1px solid #d0d0d0;
    }
    &.active-page {
        background-color: #849e1e; color: #ffffff;
        border: 1px solid #d0d0d0;
    }


}
.qty-group {
    position: relative;
    label {
        position: absolute;
        top: 4px;
        left: 8px;
        font-size: 10px;
        text-transform: uppercase;
    }

    .qty-up {
        position: absolute;
        bottom: 4px;
        right: -3px;
        cursor: pointer;
    }

    .qty-down {
        position: absolute;
        bottom: 4px;
        left: 9px;
        cursor: pointer;
    }

    .qty-field {
        text-align: center;
        padding-top: 29px !important;
        padding-bottom: 17px !important;
        border-radius: 6px;
        outline: none !important;
        -webkit-appearance: none;
        -moz-appearance: textfield;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

}
footer {

    padding-top: 24px;
    padding-bottom: 24px;
    background: #695446;
    color: #ffffff;
    font-size: 13px;
    //font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    a {
        color: #ffffff !important;
    }
    ul {
        margin-bottom: 0;
        list-style-type: none;
        padding-left: 0;
        display: block;
        & > li, & > li > a  {

            color: #ffffff;
            font-size: 13px;
            //font-weight: 700;
            font-style: normal;
            letter-spacing: 1.34px;

        }
        //@include media-breakpoint-up(lg) {
        //
        //
        //
        //    &.footer-right {
        //
        //        text-align: right;
        //
        //    }
        //
        //}

    }

}
</style>
