var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-grow-1" },
    [
      _c("search-bar"),
      _vm._v(" "),
      _c("div", { staticClass: "row my-5" }, [
        _vm.order == null
          ? _c("div", { staticClass: "col-12" }, [
              _c(
                "h1",
                {
                  staticClass: "d-block text-center",
                  staticStyle: { "font-size": "22px" },
                },
                [_vm._v("Betaling verwerken")]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.order != null && _vm.order.payment_status == 3
          ? _c("div", { staticClass: "col-12 text-center" }, [
              _c(
                "h1",
                {
                  staticClass: "d-block text-center",
                  staticStyle: { "font-size": "22px" },
                },
                [_vm._v("Bedankt voor je bestelling")]
              ),
              _vm._v(" "),
              _vm._m(0),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v("We gaan zo snel mogelijk voor je aan de slag."),
      _c("br"),
      _vm._v("Er werd een bestelbevestiging verzonden naar je emailadres."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }