var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row py-5" }, [
    _vm.product
      ? _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-8" }, [
              _c("div", [_vm._v(_vm._s(_vm.product.brand))]),
              _vm._v(" "),
              _c("h2", [_vm._v(_vm._s(_vm.product.name))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-4" }, [
              _c("div", { staticClass: "price" }, [
                _vm._v(
                  "\n                        € " +
                    _vm._s(_vm.product.price) +
                    "\n                    "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row justify-content-center py-4" }, [
            _c("div", { staticClass: "col-10" }, [
              _c("img", {
                staticClass: "w-100",
                attrs: { src: _vm.product.featured_image, alt: "" },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-4" }, [
            _c("div", {
              staticClass: "col-12",
              domProps: {
                innerHTML: _vm._s(_vm.nltobr(_vm.product.description)),
              },
            }),
          ]),
          _vm._v(" "),
          _vm.product.video
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 mt-4" }, [
                  _c("div", { staticClass: "video-container" }, [
                    _c("iframe", {
                      staticClass: "w-100",
                      attrs: {
                        src: _vm.product.video,
                        frameborder: "0",
                        allow:
                          "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                        allowfullscreen: "",
                      },
                    }),
                  ]),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }