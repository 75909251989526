var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "mb-5 search-bar-dealer", attrs: { id: "search-bar" } },
    [
      _c("div", { staticClass: "container search-bar-top" }, [
        _c("div", { staticClass: "row py-5 align-items-center" }, [
          _c("div", { staticClass: "col-12 col-lg-5" }, [
            _c("div", { staticClass: "input-group search-group" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.keyword,
                    expression: "keyword",
                  },
                ],
                staticClass: "search form-control",
                attrs: { type: "text", placeholder: "Product of merk." },
                domProps: { value: _vm.keyword },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.keyword = $event.target.value
                    },
                    (e) => (_vm.keyword = e.target.value),
                  ],
                  keyup: function ($event) {
                    $event.stopPropagation()
                    return _vm.runSearch.apply(null, arguments)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _vm._m(1),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("i", { staticClass: "fal fa-search pt-1" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 mt-3 mt-lg-0 col-lg-7" }, [
      _c(
        "div",
        {
          staticClass: "row justify-content-end",
          staticStyle: { "font-size": "1.2rem" },
        },
        [
          _c("div", { staticClass: "col-lg-auto" }, [
            _c("img", {
              staticClass: "mr-3",
              staticStyle: { height: "16px", width: "20px" },
              attrs: { src: "/img/truck_svg.svg", alt: "" },
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticStyle: {
                  color: "#ffffff",
                  display: "inline-block",
                  "padding-top": "5px",
                  "font-size": "1rem",
                },
              },
              [_vm._v("Gratis verzending vanaf €50")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-auto" }, [
            _c("img", {
              staticClass: "mr-3",
              staticStyle: { height: "16px", width: "20px" },
              attrs: { src: "/img/leaf_svg.svg", alt: "" },
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticStyle: {
                  color: "#ffffff",
                  display: "inline-block",
                  "padding-top": "5px",
                  "font-size": "1rem",
                },
              },
              [_vm._v("Met natuurlijke producten")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-lg-auto" }, [
            _c("img", {
              staticClass: "mr-3",
              staticStyle: { height: "18px", width: "20px" },
              attrs: { src: "/img/medal_svg.svg", alt: "" },
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                staticStyle: {
                  color: "#ffffff",
                  display: "inline-block",
                  "padding-top": "5px",
                  "font-size": "1rem",
                },
              },
              [_vm._v("Top service")]
            ),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }