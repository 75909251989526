<template>
    <div class="flex-grow-1">
        <search-bar></search-bar>
    <div class="container py-5 flex-grow-1">
        <div class="row">
            <div class="col-12">

                <h1>
                    Winkelmand
                </h1>
<!--                <span @click="clearCart()">Leegmaken</span>-->
            </div>
        </div>
        <div class="row mt-4 justify-content-between" v-if="cartCount == 0">
            <div class="col-12 text-center">
                Er zitten momenteel geen items in je winkelmandje.<br><br>


            </div>
        </div>
        <div class="row mt-4 justify-content-between" v-if="cartCount > 0">
            <div class="col-lg-7">

                <transition-group
                    name="staggered-fade"
                    tag="div"
                    v-bind:css="false"
                    v-on:before-enter="beforeEnter"
                    v-on:enter="enter"
                    v-on:leave="leave"
                >
                    <div class="row" v-for="(item,index) in cart" v-bind:key="item.id" v-bind:data-index="index">
                        <div class="col-12 cart-item">
                            <div class="block">
                                <div class="row">
                                    <div class="col-4">
                                        <div class="img-container">
                                        <img :src="item.product.featured_image"  class="w-100" alt="">
                                        </div>
                                    </div>
                                    <div class="col-8">
                                        <div class="row h-100">
                                            <div class="col-12 align-self-start">
                                                <div class="row justify-content-between">
                                                    <div class="col-8">
                                                        <strong>{{ item.product.brand }}</strong><br>
                                                        {{ item.product.name }}<br>
                                                        <strong>&euro; {{ formatPrice(item.product.private_customer_price)  }}</strong>
                                                    </div>
                                                    <div class="col-4 text-right">
                                                        <div class="form-group">
                                                            <input type="number" class="text-center product-qty-input" @change="changeQty" :data-index="item.product.id" min="1" step="1" :value="item.qty">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 align-self-end">
                                                <div class="row justify-content-between">
                                                    <div class="col-8">
                                                        <a href="#" class="remove-from-cart text-uppercase" @click.prevent="removeFromCart(index)">Verwijder item</a>
                                                    </div>
                                                    <div class="col-4 text-right">
                                                        <div class="item-price">
                                                            &euro; {{ formatPrice(item.product.private_customer_price * item.qty) }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </transition-group>

            </div>
            <div class="col-lg-5">
                <div class="border">
                    <div class="row">
                        <div class="col-12 totals">
                            <div class="row justify-content-between">
                                <div class="col-12 font-weight-bold text-uppercase py-4">
                                    <h5 class="font-weight-bold">Totalen</h5>
                                </div>
                                <div class="col-12 pb-2">
                                    <div class="row justify-content-between">
                                        <div class="col-auto py-2">
                                            Subtotaal
                                        </div>
                                        <div class="col-auto py-2 cart-subtotal" id="cart-subtotal">
                                            &euro; {{ formatPrice(cartTotals.subtotal) }}
                                        </div>
                                    </div>
<!--                                    <div class="row justify-content-between">-->
<!--                                        <div class="col-auto py-2">-->
<!--                                            Verzending-->
<!--                                        </div>-->
<!--                                        <div class="col-auto py-2">-->
<!--                                            gratis-->
<!--                                        </div>-->
<!--                                    </div>-->
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12 totals border-top">
                            <div class="row justify-content-between">
                                <div class="col-auto font-weight-bold py-4">
                                    Totaalprijs (incl. BTW)
                                </div>
                                <div class="col-auto font-weight-bold py-4 cart-total" id="cart-total">
                                    &euro; {{ formatPrice(cartTotals.total) }}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="col-12">
                        <div class="form-group">
                            <router-link :to="{ name: 'checkout'}" class="site-btn w-100 text-center" >Bestelling afronden</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
    </div>
</template>

<script>
import SearchBar from './parts/SearchBar';
import CategoryFilter from "./parts/CategoryFilter";
import BrandCategoryFilter from "./parts/BrandCategoryFilter";
export default {
    data() {

        return {}
    },
    computed: {

        cart() {
            return this.$store.state.StoreCart
        },
        cartTotals() {
            return this.$store.getters.totals
        },
        cartCount() {
          return this.$store.getters.count
        }


    },
  created() {
    if (this.cartCount == 0) {
        setTimeout( () => this.$router.push({ path: '/'}), 2000);

    }
  },
    watch: {
        cartCount(newCount, oldCount) {
            if(newCount == 0) {
                this.$router.push('/');
            }
        }
    },
    components: {
        SearchBar
    },
    methods: {
        clearCart() {
            this.$store.commit( 'CLEAR_Cart')
        },
        removeFromCart(index) {
            this.$store.commit('REMOVE_Item', index)
        },
        changeQty: function(event) {
          this.$store.commit('CHANGE_Item',{index: event.target.dataset.index, qty: event.target.value })
        },
        beforeEnter: function (el) {
            el.style.opacity = 0
            el.style.height = 0
        },
        enter: function (el, done) {
            var delay = el.dataset.index * 150
            setTimeout(function () {
                Velocity(
                    el,
                    {opacity: 1, height: '1.6em'},
                    {complete: done}
                )
            }, delay)
        },
        leave: function (el, done) {
            var delay = el.dataset.index * 150
            setTimeout(function () {
                Velocity(
                    el,
                    {opacity: 0, height: 0},
                    {complete: done}
                )
            }, delay)
        },
        formatPrice(value) {

            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

        },
    }
}
</script>
<style lang="scss">

.remove-from-cart {
    cursor: pointer;
    text-align: center;
    img {
        width: 12px;
    }
}

.img-container {
    border: 1px solid #cfcfcf;
    position: relative;
    padding-bottom: 100%;
    background-color: white;

    img {
        position: absolute;
        top: 15px;
        left: 0;
        height: calc(100% - 30px);
        width: calc(100% - 30px);
        -o-object-fit: contain;
        object-fit: contain;
        background-color: #ffffff;
    }

}
</style>
