var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column justify-content-between",
      staticStyle: { "min-height": "100vh" },
    },
    [
      _c("header", [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "navbar px-0" }, [
            _c(
              "div",
              {
                staticClass:
                  "row align-items-center justify-content-between w-100",
              },
              [
                _c("div", { staticClass: "col-6 col-lg-4" }, [
                  _c(
                    "a",
                    { staticClass: "navbar-brand", attrs: { href: "/" } },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.webshop.logo_navbar,
                          alt: "Kanters Haar- en Nagelstyling",
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6 col-lg-8" }, [
                  _c("div", { staticClass: "tm-header-mobile d-lg-none" }, [
                    _c(
                      "div",
                      {
                        attrs: {
                          "show-on-up": "",
                          animation: "uk-animation-slide-top",
                          "sel-target": ".uk-navbar-container",
                        },
                      },
                      [
                        _c("div", { staticClass: "uk-navbar-container" }, [
                          _c(
                            "nav",
                            {
                              staticClass: "uk-navbar",
                              attrs: { "uk-navbar": "" },
                            },
                            [
                              _c("div", { staticClass: "uk-navbar-right" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "uk-navbar-toggle",
                                    attrs: {
                                      href: "#tm-mobile",
                                      "uk-toggle": "animation: true",
                                      "aria-expanded": "false",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "uk-icon uk-navbar-toggle-icon",
                                        attrs: { "uk-navbar-toggle-icon": "" },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              width: "20",
                                              height: "20",
                                              viewBox: "0 0 20 20",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("rect", {
                                              attrs: {
                                                y: "9",
                                                width: "20",
                                                height: "2",
                                              },
                                            }),
                                            _c("rect", {
                                              attrs: {
                                                y: "3",
                                                width: "20",
                                                height: "2",
                                              },
                                            }),
                                            _c("rect", {
                                              attrs: {
                                                y: "15",
                                                width: "20",
                                                height: "2",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "navbar-nav flex-row justify-content-end text-center mt-0",
                                  },
                                  [
                                    _c(
                                      "li",
                                      { staticClass: "nav-item col-auto pr-0" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            staticClass:
                                              "nav-link mini-cart-link",
                                            attrs: { to: { name: "cart" } },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fal fa-shopping-cart",
                                              staticStyle: {
                                                "font-size": "1.5rem",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _vm.$store.getters.count > 0
                                              ? _c(
                                                  "small",
                                                  {
                                                    staticClass:
                                                      "badge badge-pill badge-dark",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$store.getters.count
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _c("div", {
                      staticClass: "uk-sticky-placeholder",
                      staticStyle: { height: "100px", margin: "0px" },
                      attrs: { hidden: "" },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass:
                        "navbar-nav flex-row justify-content-end text-right row d-none d-lg-block",
                    },
                    [
                      _c(
                        "li",
                        { staticClass: "nav-item col-auto" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link d-inline-block w-auto",
                              attrs: { to: { name: "home" } },
                            },
                            [
                              _vm._v(
                                "\n                                    Home\n                                "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.websiteMenuItems, function (menuItem, index) {
                        return menuItem.title !== "Webshop"
                          ? _c("li", { staticClass: "nav-item col-auto" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link d-inline-block w-auto",
                                  attrs: { href: menuItem.url },
                                },
                                [
                                  _vm._v(
                                    "\n                                    " +
                                      _vm._s(menuItem.title) +
                                      "\n                                "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e()
                      }),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "nav-item col-auto" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link d-inline-block w-auto",
                              attrs: { to: { name: "webshop" } },
                            },
                            [
                              _vm._v(
                                "\n                                    Webshop\n                                "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "nav-item col-auto pr-0" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link mini-cart-link",
                              attrs: { to: { name: "cart" } },
                            },
                            [
                              _c("i", {
                                staticClass: "fal fa-shopping-cart",
                                staticStyle: { "font-size": "1.5rem" },
                              }),
                              _vm._v(" "),
                              _vm.$store.getters.count > 0
                                ? _c(
                                    "small",
                                    {
                                      staticClass:
                                        "badge badge-pill badge-dark",
                                    },
                                    [_vm._v(_vm._s(_vm.$store.getters.count))]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "tm-mobile", hidden: "" } }, [
        _c(
          "div",
          { staticClass: "uk-background-default uk-padding uk-text-center" },
          [
            _c(
              "div",
              {
                staticClass: "uk-child-width-1-1 uk-grid uk-grid-stack",
                attrs: { "uk-grid": "" },
              },
              [
                _c("div", [
                  _c("div", { staticClass: "uk-panel" }, [
                    _c(
                      "ul",
                      { staticClass: "uk-nav uk-nav-default uk-nav-center" },
                      [
                        _vm._l(
                          _vm.websiteMenuItems,
                          function (menuItem, index) {
                            return menuItem.title !== "Webshop"
                              ? _c("li", { staticClass: "nav-item col-auto" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "nav-link d-inline-block w-auto",
                                      attrs: { href: menuItem.url },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(menuItem.title) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e()
                          }
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          {
                            staticClass: "nav-item col-auto uk-navbar-toggle",
                            attrs: {
                              href: "#tm-mobile",
                              "uk-toggle": "animation: true",
                              "aria-expanded": "false",
                            },
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link d-inline-block w-auto",
                                attrs: { to: { name: "webshop" } },
                                on: {
                                  click: function ($event) {
                                    _vm.document
                                      .querySelector(".uk-navbar-toggle")
                                      .trigger("click")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    Webshop\n                                "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { name: "slide", mode: "out-in" },
          on: {
            beforeLeave: _vm.fadeBeforeLeave,
            enter: _vm.fadeEnter,
            afterEnter: _vm.fadeAfterEnter,
          },
        },
        [_c("router-view")],
        1
      ),
      _vm._v(" "),
      _c("footer", { staticClass: "footer" }, [
        _c("div", { staticClass: "uk-container" }, [
          _c(
            "div",
            {
              staticClass: "tm-grid-expand uk-grid-margin uk-grid",
              attrs: { "uk-grid": "" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "uk-grid-item-match uk-flex-middle uk-width-1-2@m uk-first-column",
                },
                [
                  _c("div", { staticClass: "uk-panel uk-width-1-1" }, [
                    _c("div", {
                      staticClass: "uk-panel uk-margin",
                      domProps: {
                        innerHTML: _vm._s(_vm.variables["opening-hours"]),
                      },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "uk-grid-item-match uk-flex-middle uk-width-1-2@m",
                },
                [
                  _c("div", { staticClass: "uk-panel uk-width-1-1" }, [
                    _c("div", { staticClass: "uk-panel uk-margin" }, [
                      _c("ul", { staticClass: "footer-left" }, [
                        _c("li", [
                          _vm._v(
                            _vm._s(_vm.webshop.dealer.address) +
                              " " +
                              _vm._s(_vm.webshop.dealer.housenumber)
                          ),
                          _vm.webshop.dealer.addition
                            ? _c("span", [
                                _vm._v(
                                  "/" + _vm._s(_vm.webshop.dealer.addition)
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(
                            " | " +
                              _vm._s(_vm.webshop.dealer.zip) +
                              " " +
                              _vm._s(_vm.webshop.dealer.city)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [_vm._v(_vm._s(_vm.webshop.dealer.phone))]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "mailto:" + _vm.webshop.dealer.email,
                              },
                            },
                            [_vm._v(_vm._s(_vm.webshop.dealer.email))]
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._m(0),
                    _vm._m(1),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "uk-panel uk-margin" }, [
      _c("ul", { staticClass: "footer-right" }, [
        _c("li", [
          _c(
            "a",
            { attrs: { href: "https://kanters.be/algemene-voorwaarden/" } },
            [_vm._v("Algemene voorwaarden")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "https://kanters.be/levertijden-en-verzendkosten/",
              },
            },
            [_vm._v("Levertijden en verzendkosten")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "https://kanters.be/retourneren/" } }, [
            _vm._v("Retourneren"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "uk-panel uk-margin" }, [
      _c("ul", { staticClass: "footer-right", staticStyle: { top: "-20px" } }, [
        _c("li", [_vm._v("© 2021")]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "https://kanters.be//disclaimer/" } }, [
            _vm._v("Disclaimer"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "https://kanters.be//privacy-policy/" } }, [
            _vm._v("Privacy Policy"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.kanters.be",
                target: "_blank",
                rel: "noopener",
              },
            },
            [_vm._v("Kanters")]
          ),
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.creatic.com",
                target: "_blank",
                rel: "noopener",
              },
            },
            [_vm._v("Creatic.Agency")]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }