<template>
    <div class="row py-5">
        <div class="col-12" v-if="product">
            <div class="row">
                <div class="col-8">
                    <div>{{ product.brand }}</div>
                    <h2>{{ product.name}}</h2>
                </div>
                <div class="col-4">
                    <div class="price">
                        &euro; {{ product.price }}
                    </div>
                </div>
            </div>
            <div class="row justify-content-center py-4">
                <div class="col-10">
                    <img :src="product.featured_image" class="w-100" alt="">
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12" v-html="nltobr(product.description)">

                </div>
            </div>
            <div class="row" v-if="product.video">

                <div class="col-12 mt-4">
                    <div class="video-container">
                        <iframe class="w-100" :src="product.video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
<!--        <div class="col-12">-->
<!--            <h1>test</h1>-->
<!--            <span>{{ error }}</span>-->
<!--            <qrcode-stream @decode="onDecode" @init="onInit" />-->
<!--        </div>-->
    </div>
</template>

<script>
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
export default {
    data ()  {
        return {
           qrCode: this.$route.params.code,
            product: {},
            error: ''

        }
    },
    async mounted() {

        //this.$store.dispatch("allProductsFromDatabase");
        await this.$http.get("/api/product-by-qr", { params: { qr: this.$route.params.code}})

            .then((response)=>{
                //context.commit("products",response.data.products) //categories will be run from mutation
                this.product = response.data.product

            })

            .catch(()=>{

                console.log("Error........")

            })


    },
    components: {
        QrcodeStream,
        QrcodeDropZone,
        QrcodeCapture
    },
    methods: {
        nltobr(str) {
            return str.replace(/\n/g, '<br />')
        }
    },
    computed: {
        /* getAllProducts(){ //final output from here
             return this.$store.getters.getProductsFormGetters;
         },*/


    },
    created: function() {

    }


}
</script>
<style>
.price {
    font-size: 24px;
    font-weight: 700;
    color: #0092c9;
}
.video-container {
    position:relative;
    padding-bottom:56.25%;
    padding-top:30px;
    height:0;
    overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}
</style>
