<template>
    <div>

        <nav class="navbar navbar-expand-md navbar-light bg-white shadow-sm">
            <div class="container-fluid">
                <a class="navbar-brand" href="/">
                    <img :src="asset('img/Logo-Kanters_bruin.png')" alt="Kanters Haar- en Nagelstyling">
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <!-- Left Side Of Navbar -->
                    <ul class="navbar-nav mr-auto">

                    </ul>

                    <!-- Right Side Of Navbar -->
                    <ul class="navbar-nav ml-auto">

                        <router-link :to="{ name: 'home' }" tag="li" class="nav-item"><a href="#" class="nav-link">Home</a></router-link>
                        <router-link to="/checkout" tag="li" class="nav-item"><a href=""  class="nav-link">Checkout</a></router-link>


                    </ul>
                </div>
            </div>
        </nav>
        <section id="admin-subheader">
            <div class="container h-100">
                <div class="row align-items-center h-100">
                    <div class="col-12">
                        <h1>Dealer<br><span class="ml-5">Bestelplatform</span></h1>
                    </div>
                </div>
            </div>

        </section>
    </div>
</template>

<script>

export default {
    data: function() {
        return {}
    }
}
</script>
