<template>
    <div class="flex-grow-1">
        <search-bar></search-bar>
    <div class="row my-5">
        <div class="col-12" v-if="order == null">
            <h1 class="d-block text-center" style="font-size: 22px">Betaling verwerken</h1>


        </div>
        <div class="col-12 text-center" v-if="order != null && order.payment_status == 3">
            <h1 class="d-block text-center" style="font-size: 22px">Bedankt voor je bestelling</h1>
            <p>We gaan zo snel mogelijk voor je aan de slag.<br>Er werd een bestelbevestiging verzonden naar je emailadres.</p>

        </div>
    </div>
    </div>
</template>

<script>
import SearchBar from './parts/SearchBar';
export default {
    data ()  {
        return {
            order: null
        }
    },
    components: {
        SearchBar
    },
    methods: {

    },
    computed: {

    },
    beforeMount() {

    },
    created: function() {
        console.log(this.$route.query.id)
        var data =  {
            uuid: this.$route.query.id
        }
        let thisComponent = this;
        this.$http.post(this.api_url("/order/check-payment"), data)
            .then(function (response) {

                //if(response.data.order.payment_status === 3) {
                    thisComponent.order = response.data.order;
                    thisComponent.$store.commit( 'CLEAR_Cart');

               // } else {

                 //   thisComponent.$router.push({name: "checkout"})

               // }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


}
</script>
