var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex-grow-1" }, [
    _c("section", { staticClass: "home-header px-4" }, [
      _c("div", { staticClass: "container py-5" }, [
        _c(
          "div",
          { staticClass: "row align-items-center justify-content-between" },
          [
            _c("div", {
              staticClass: "col-lg-6",
              domProps: { innerHTML: _vm._s(_vm.variables["header-content"]) },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "col-lg-6 d-block text-center" }, [
              _c("img", {
                staticClass: "inline",
                attrs: { src: _vm.webshop.logo_header },
              }),
            ]),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "top-products py-5" }, [
      _c("div", { staticClass: "container" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row mb-5" },
          [
            _vm._l(_vm.products, function (product, index) {
              return _c(
                "div",
                {
                  key: product.id,
                  staticClass: "col-lg-3 mb-5 list-complete-item",
                  attrs: { "data-index": index },
                },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "product-item",
                      attrs: {
                        to: {
                          name: "product",
                          params: {
                            category: null,
                            subcategory: null,
                            slug: product.slug,
                          },
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "img-container" }, [
                        _c("img", {
                          staticClass: "w-100",
                          attrs: { src: product.featured_image, alt: "" },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "product-info" }, [
                        _c("div", { staticClass: "row align-items-center" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("strong", [_vm._v(_vm._s(product.brand))]),
                            _c("br"),
                            _vm._v(" "),
                            _c("strong", [_vm._v(_vm._s(product.name))]),
                            _c("br"),
                            _vm._v(
                              "\n                                " +
                                _vm._s(product.short_description)
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c("span", { staticClass: "price" }, [
                              _vm._v(
                                "€ " +
                                  _vm._s(
                                    _vm.formatPrice(
                                      product.private_customer_price
                                    )
                                  )
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              )
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-lg-3 mb-5" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "webshop-link d-flex justify-content-center align-items-center",
                    attrs: { to: { name: "webshop" } },
                  },
                  [
                    _c("div", [
                      _vm._v(
                        "\n                        Bekijk meer\n                    "
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 pt-5 pb-4" }, [
        _c("h2", [_vm._v("Topproducten")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }