<template>
    <div class=" col-3 mb-5">
    <div class="el-item new-prod-item" role="group" aria-label="1 of 10" aria-roledescription="slide" tabindex="-1" aria-hidden="false">
        <div v-if="label && label !==''" class="ribbon">Topproduct</div>
        <router-link :to="{name: 'product', params: {category: (category.parent) ? category.parent.slug : null,  subcategory: category.slug, slug: product.slug}}" class="position-relative d-block"><img decoding="async" class="products-img" :src="product.featured_image" ></router-link>
        <div class="products-info">
            <span class="name">{{ product.name }}</span><br>
            <span class="brand">{{ product.brand }}</span><br>
            <span class="description">{{ product.short_description}}</span>
            <span class="price">€ {{ formatPrice(product.private_customer_price) }}</span>
        </div>
        <div class="products-info-bottom">
            <div class="qty-field">
                <input type="number" :value="qty" min="1" step="1" name="qty">
                <div class="qty-controls">
                    <span @click="qtyPlus" class="plus">+</span>
                    <span @click="qtyMin" class="minus">-</span>
                </div>
            </div>
            <!--<div class="add-to-cart"><a href="">Toevoegen</a></div>-->
            <div class="add-to-cart">
                <input type="hidden" value="https://webshop.kanters.be/producten/salonmateriaalolivia-garden/fingerbrush-polar-blue" name="product-url">
                <button @click.prevent="addToCart" class="add-to-cart-btn" >Toevoegen</button>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            qty: 1
        }
    },
    props: {
        product : {
            type: Object,
            required: true
        },
        category : {
            type: Object,
            required: true
        },
        label : {
            type: String,
        }
    },
    methods: {
        qtyPlus() {

            this.qty++;

        },
        qtyMin() {

            if(this.qty >1) {

                this.qty--;

            }

        },
        formatPrice(value) {

            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

        },
        addToCart() {
            this.$store.commit('ADD_Item', {product: this.product, qty: this.qty})
            // this.message.content = this.product.brand + ' ' + this.product.name + " is toegevoegd aan het winkelmandje.";
            // this.message.display = true;
        },
    }
}
</script>

<style scoped lang="scss">
@use "../scss/1-lib/variables";
@use "../scss/1-lib/functions";

.new-prod-item,
.fav-prod-item {
    border: 2px solid variables.$black;
    padding: 20px !important;
    height: 100%
}

.new-products-wrapper,
.favorite-products-wrapper {
    ul {
        margin-left: 0;

        > li {
            margin: 0 10px;
            width: calc(20% - 18px);
            display: flex;
            flex-direction: column;
        }
    }
}

.products-img {
    width: 183px;
    height: 183px;
    object-fit: contain;
    margin-bottom: 30px;
}

.products-info {
    text-align: left;

    .name {
        font-size: functions.pxToRem(15);
        color: variables.$black;
        font-weight: 500;
        line-height: 1.5;
    }

    .brand {
        font-size: functions.pxToRem(14);
        color: variables.$black;
        font-weight: 300;
        line-height: 1.5;
    }

    .price {
        font-size: functions.pxToRem(16);
        color: variables.$black !important;
        font-weight: 700;
        line-height: 1.5;
        margin-top: 8px;
        display: block;
    }
}

.products-info-bottom {
    display: flex;
    margin-top: 15px;
    align-items: flex-end;
    flex: 1 0 auto;

    .qty-field {
        display: flex;
        align-items: center;

        input[type="number"] {
            width: 46px;
            height: 50px;
            text-align: center;
            -moz-appearance: textfield;
            border: 2px solid variables.$black;
            margin-right: -2px;
            color: variables.$black;
            font-family: variables.$font-txt;
            font-size: functions.pxToRem(16);
            font-style: normal;
            font-weight: 500;
            line-height: 1.5;
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input[type="number"]:focus {
            outline: none;
            box-shadow: none;
        }

        .qty-controls {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .plus {
                display: block;
                width: 26px;
                height: 26px;
                line-height: 22px;
                text-align: center;
                border: 2px solid variables.$black;
                cursor: pointer;
                user-select: none;
                color: variables.$black;
                font-family: variables.$font-txt;
                font-size: functions.pxToRem(17);
            }

            .minus {
                display: block;
                width: 26px;
                height: 26px;
                line-height: 22px;
                text-align: center;
                border: 2px solid variables.$black;
                cursor: pointer;
                user-select: none;
                margin-top: -2px;
                color: variables.$black;
                font-family: variables.$font-txt;
                font-size: functions.pxToRem(17);
            }
        }
    }

    .add-to-cart {
        min-height: 50px;
        margin-left: -2px;
        display: flex;
    }

    .add-to-cart-btn {
        all: unset;
        cursor: pointer;
        border: 2px solid variables.$black;
        color: variables.$primary-900;
        font-family: variables.$font-txt;
        font-size: functions.pxToRem(15);
        font-style: normal;
        font-weight: 300;
        line-height: 1.5;
        padding: 8px 15px;
        background: variables.$primary-100;
    }
}

.ribbon {
    --f: 20px;
    --r: 10px;
    --t: 16px;
    position: absolute;
    z-index: 999;
    right: 0 !important;
    inset: var(--t) calc(-1* var(--f)) auto auto;
    padding: 4px 19px var(--f) calc(10px + var(--r));
    clip-path:
        polygon(0 0,100% 0,100% calc(100% - var(--f)),calc(100% - var(--f)) 80%,
            calc(100% - var(--f)) calc(100% - var(--f)),0 calc(100% - var(--f)),
            0 calc(50% - var(--f)/2));
    background: variables.$primary-100;
    box-shadow: 0 calc(-1*var(--f)) 0 inset darken(variables.$primary-100, 20);
    text-transform: uppercase;
    color: #ffffff;
    height: 60px;
}
</style>
