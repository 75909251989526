<template>
    <section id="search-bar" class="search-bar-webshop mb-5">
        <div class="container search-bar-top">
            <div class="row py-5 align-items-center">
                <div class="col-12 col-lg-5">
                    <div class="input-group search-group">
                        <div class="input-group-prepend">
                            <i class="fal fa-search pt-1"></i>
                        </div>
                        <input type="text" v-model="keyword" class="search form-control" :placeholder="__('webshop.zoek-placeholder')" @keyup.stop="runSearch">
                    </div>

                </div>
                <div class="col-12 mt-3 mt-lg-0 col-lg-7">
                    <div class="row justify-content-end" style="font-size:1.2rem">
                        <div class="col-lg-auto">
                            <img src="/img/truck_svg.svg" class="mr-3" alt="" style="height: 16px; width: 20px; filter: invert(100%)"> <span style="color: #000000; display: inline-block; padding-top: 5px; font-size: 1rem;">{{ __('webshop.Snelle verzending') }}</span>
                        </div>
                        <div class="col-lg-auto">
                            <img src="/img/leaf_svg.svg" class="mr-3" alt="" style="height: 16px; width: 20px;filter: invert(100%)"> <span style="color: #000000; display: inline-block; padding-top: 5px; font-size: 1rem;"">{{ __('webshop.Met natuurlijke producten') }}</span>
                        </div>
                        <div class="col-lg-auto">
                            <img src="/img/medal_svg.svg" class="mr-3" alt="" style="height: 18px; width: 20px;filter: invert(100%)"> <span style="color: #000000; display: inline-block; padding-top: 5px; font-size: 1rem;"">{{ __('webshop.Top service') }}</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="container search-bar-bottom">
            <div class="row py-3 align-items-center">
                <div class="col-12">
                    <p style="margin-top:0px;margin-bottom:0px;color: #ffffff;font-size: 17px;text-align:center;">Ben jij een professional in haar of beauty? <a href="https://store.kanters.be/bestelplatform/login" style="color:#ffffff;font-weight:bold;text-decoration: underline;text-underline-position: under;">Klik hier</a> voor uw webshop <strong>voor professionals</strong>!</p>
                </div>
            </div>
        </div>
    </section>

</template>

<script>
export default {
    data ()  {

        return {

           keyword: (this.$route.query.search) ? this.$route.query.search : '',
            timer :null

        }

    },
    /*async mounted() {

        await this.$http.get( this.api_url("/categories"))

            .then((response)=>{

                this.categories = response.data.categories

            })

            .catch(()=>{

                console.log("Error........")

            })


    },*/
    components: {

    },
    methods: {

        runSearch () {
            var self = this
            //this.$router.push({ query: { ...this.$route.query, search: this.keyword } });
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
            this.timer = setTimeout(function() {

            if (this.keyword) {

                this.UpdateQueryString('search', self.keyword)
                    if(self.$route.matched.some(({ name }) => name === 'home')) {
                        window.location.reload();
                    } else {

                        self.$router.push({name: 'home', query: {search: self.keyword,t: new Date().getTime(),}})

                    }

            } else {
                self.UpdateQueryString('search', self.keyword)
                if(self.$route.matched.some(({ name }) => name === 'home')) {
                    window.location.reload();
                } else {

                    self.$router.push({name: 'home', query: {search: self.keyword,t: new Date().getTime(),}})

                }

            }
            }, 1000);


        },
        UpdateQueryString(key, value) {

            var queryParams = new URLSearchParams(window.location.search);
            queryParams.set(key, value);
            history.replaceState(null, null, "?"+queryParams.toString());

        }

    },
    computed: {


    },
    created: function() {

    }


}
</script>

<style lang="scss" scoped>
 ul {
     list-style-type: none;
     padding-left: 0;
     li {
         display: block;
         a {
             padding: 4px 0;
             color: #000000;
             text-decoration: none;
             text-transform: uppercase;
             &:hover, &.active {
                 color: #0092C9;
             }
             &:not(.active) ~ ul {
                 display: none;
             }
         }
         ul {
             li{
                 padding-left: 10px;
                 a {
                     text-transform:none;

                 }
             }
         }

     }
 }
</style>
