<template>
    <div class="flex-grow-1">
        <search-bar></search-bar>
    <div class="container py-5 flex-grow-1">
        <ValidationObserver v-slot="{ handleSubmit }">
        <form action="/payment" method="post" class="checkout-form" @submit.prevent="handleSubmit(submit)">
        <div class="row">
            <div class="col-12">

                <h1>
                    Bestellen
                </h1>
            </div>
        </div>
        <div class="row mt-4 justify-content-between">
            <div class="col-lg-7">

                <div class="form-group inputs">
                    <div class="row" @change="findDealers()">

                        <div class="col-6">
                            <validation-provider rules="required" v-slot="{ errors }">
                                <div class="input-container">
                                <label for="first_name">Voornaam</label>

                                    <input id="first_name" @focus="inputFocus" @blur="inputBlur" type="text" v-model="checkout.first_name" name="first_name">
                                </div>

                                <span class="invalid-feedback d-block">{{ errors[0] }}</span>
                            </validation-provider>
                        </div>

                        <div class="col-6">
                            <validation-provider rules="required" v-slot="{ errors }">
                                <div class="input-container">
                                    <label for="name">Naam</label>
                                     <input id="name" @focus="inputFocus" @blur="inputBlur"  type="text" v-model="checkout.name" name="name">
                                </div>
                                <span class="invalid-feedback d-block">{{ errors[0] }}</span>
                            </validation-provider>
                        </div>


                        <div class="col-8 mt-4">
                            <validation-provider rules="required" v-slot="{ errors }">
                                <div class="input-container">
                                    <label for="address_street">Straat</label>
                                    <input id="address_street" @focus="inputFocus" @blur="inputBlur" type="text" v-model="checkout.address_street" name="address_street">
                                </div>
                                <span class="invalid-feedback d-block">{{ errors[0] }}</span>
                            </validation-provider>
                        </div>
                        <div class="col-2 mt-4">
                            <validation-provider rules="required" v-slot="{ errors }">
                                <div class="input-container">
                                    <label for="address_housenumber">Nr</label>
                                    <input id="address_housenumber"  @focus="inputFocus" @blur="inputBlur" type="text" v-model="checkout.address_housenumber" name="address_housenumber">
                                </div>
                                <span class="invalid-feedback d-block">{{ errors[0] }}</span>
                            </validation-provider>
                        </div>
                        <div class="col-2 mt-4">
                            <div class="input-container">
                                <label for="address_addition">Toev.</label>
                                <input id="address_addition" @focus="inputFocus" @blur="inputBlur"  type="text" v-model="checkout.address_addition" name="address_addition">
                            </div>

                        </div>
                        <div class="col-4 mt-4">
                            <validation-provider rules="required" v-slot="{ errors }">
                                <div class="input-container">
                                    <label for="address_zip">Postcode</label>
                                    <input id="address_zip" @focus="inputFocus" @blur="inputBlur"  type="text" v-model="checkout.address_zip" name="address_zip">
                                </div>
                                <span class="invalid-feedback d-block">{{ errors[0] }}</span>
                            </validation-provider>
                        </div>
                        <div class="col-8 col-lg-4 mt-4">
                            <validation-provider rules="required" v-slot="{ errors }">
                                <div class="input-container">
                                    <label for="address_city">Plaats</label>
                                    <input id="address_city" @focus="inputFocus" @blur="inputBlur"  type="text" v-model="checkout.address_city" name="address_city">
                                </div>
                                <span class="invalid-feedback d-block">{{ errors[0] }}</span>
                            </validation-provider>
                        </div>
                        <div class="col-lg-4 mt-4">
                            <validation-provider rules="required" v-slot="{ errors }">
                                <div class="input-container">
                                    <select name="address_country" v-model="checkout.address_country" id="">
                                        <option value="">Land</option>
                                        <option value="België">België</option>
                                        <option value="Nederland">Nederland</option>
                                    </select>
                                </div>
                                <span class="invalid-feedback d-block">{{ errors[0] }}</span>
                            </validation-provider>
                        </div>
                        <div class="col-6 mt-4">
                            <validation-provider rules="required" v-slot="{ errors }">
                                <div class="input-container">
                                <label for="email">E-mailadres</label>
                                    <input id="email" @focus="inputFocus" @blur="inputBlur"  type="email" v-model="checkout.email" name="email">
                                </div>
                                    <span class="invalid-feedback d-block">{{ errors[0] }}</span>
                            </validation-provider>
                        </div>
                        <div class="col-6 mt-4">
                            <div class="input-container">
                                <label for="phone">Telefoon</label>
                                <input id="phone" @focus="inputFocus" @blur="inputBlur"  type="phone" v-model="checkout.phone" name="phone">
                            </div>
                        </div>
                    </div>
                </div>

                <transition name="fade">
                    <validation-provider rules="required" :customMessages="{required: 'Gelieve een levermethode te selecteren.'}" v-slot="{ errors }">
                        <div class="row mt-4" v-if="sortedDealers.length > 0">
                            <div class="col-12 mb-3">
                                <strong>Kies een levermethode</strong>
                            </div>
                            <label class="col-md-6">
                                <div class="border">
                                    <div class="row align-items-center">
                                        <div class="col-1">
                                            <div class="">
                                                <input type="radio" class="" name="delivery" v-model="checkout.deliveryMethod" id="ship" value="ship">
                                            </div>
                                        </div>
                                        <div class="col-10">
                                            <div class="row align-items-center">
                                                <div class="col-auto">
                                                    <i class="fal fa-2x fa-shipping-fast"></i>
                                                </div>
                                                <div class="col">
                                                    Leveren
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </label>
<!--                            <label class="col-md-6">-->
<!--                                <div class="border">-->
<!--                                    <div class="row align-items-center">-->
<!--                                        <div class="col-1">-->
<!--                                            <div class="">-->
<!--                                                <input type="radio" class="" name="delivery" v-model="checkout.deliveryMethod" id="pickup" value="pickup">-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                        <div class="col-10">-->
<!--                                            <div class="row align-items-center">-->
<!--                                                <div class="col-auto">-->
<!--                                                    <i class="fal fa-store-alt fa-2x"></i>-->
<!--                                                </div>-->
<!--                                                <div class="col">-->
<!--                                                    Ophalen-->
<!--                                                </div>-->
<!--                                            </div>-->

<!--                                        </div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </label>-->

                        <span class="col-12 invalid-feedback d-block">{{ errors[0] }}</span>
                        </div>
                    </validation-provider>
                </transition>
            </div>
            <div class="col-lg-5">
                <div class="border">
                    <div class="row">
                        <div class="col-12 totals">
                            <div class="row justify-content-between">
                                <button type="button" class="btn" @click.prevent="showProducts = true" data-bs-target="#exampleModal">
                                    Bekijk producten
                                </button>

                                <!-- Modal -->
                                <transition name="fade" :duration="250">
                                <div class="modal fade show" v-if="showProducts" style="display: block;background: rgba(0,0,0, .6);" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel">
                                    <div class="modal-dialog modal-dialog-centered">
                                        <div class="modal-content">

                                            <div class="modal-body p-4">
                                                <div class="block mb-3 py-2">
                                                    <div class="row">
                                                        <div class="col-7">Product</div>
                                                        <div class="col-3 text-center">Aantal</div>
                                                        <div class="col-2 text-right">Totaal</div>
                                                    </div>
                                                </div>
                                                <div class="block mb-3" v-for="(item,index) in cart">
                                                    <div  class="row align-items-center cart-item">
                                                        <div class="col-2">
                                                            <img :src="item.product.featured_image"  class="w-100" alt="">
                                                            <input type="hidden" :name="'products[' + index + '][id]'" :value="item.product.id">
                                                            <input type="hidden" :name="'products[' + index + '][qty]'" :value="item.qty">
                                                        </div>
                                                        <div class="col-5">
                                                           <strong>{{ item.product.brand }}</strong><br> {{ item.product.name}}<br> &euro; {{ formatPrice(item.product.private_customer_price) }}
                                                        </div>
                                                        <div class="col-3 text-center">
                                                            <div class="input-container filled">
                                                                {{ item.qty }}
                                                            </div>
                                                        </div>
                                                        <div class="col-2 text-right">
                                                            <div class="item-price">
                                                                &euro; {{ formatPrice(item.product.private_customer_price * item.qty) }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn" @click.prevent="showProducts = false" >Sluiten</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </transition>
                                <div class="col-12 font-weight-bold text-uppercase py-4">
                                    <h5 class="font-weight-bold">Totalen</h5>
                                </div>
                                <div class="col-12 pb-2">
                                    <div class="row justify-content-between">
                                        <div class="col-auto py-2">
                                            Subtotaal
                                        </div>
                                        <div class="col-auto py-2 cart-subtotal" id="cart-subtotal">
                                            &euro; {{ formatPrice(cartTotals.subtotal) }}
                                        </div>
                                    </div>
                                    <div class="row justify-content-between" v-if="cartTotals.shipping">
                                        <div class="col-auto py-2">
                                            Verzending
                                        </div>
                                        <div class="col-auto py-2">
                                            &euro; {{ formatPrice(cartTotals.shipping) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12 totals border-top">
                            <div class="row justify-content-between">
                                <div class="col-auto font-weight-bold py-4">
                                    Totaalprijs (incl. BTW)
                                </div>
                                <div class="col-auto font-weight-bold py-4 cart-total" id="cart-total">
                                    &euro; {{ formatPrice(cartTotals.total) }}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12 payment-methods border-top"><div class="row"><div class="col-12 font-weight-bold pt-4">
                        Betaalmethode
                        </div>
                    </div>
                        <div class="row">
                            <div class="col-12">
                                <validation-provider rules="required" v-slot="{ errors }">
                                    <label class="d-block" v-for="method in paymentMethods">
                                        <div class="row py-3 align-items-center">
                                            <div class="col-1"><input type="radio" :id="method.id" v-model="checkout.paymentMethod" name="payment_method" :value="method.id">
                                            </div>
                                            <div class="col-2">
                                                <img :src="method.image.size2x" alt="" class="w-100">
                                            </div>
                                            <div class="col 9">
                                                <strong>{{ method.description }}</strong>
                                            </div>
                                        </div>
                                    </label>
                                    <div class="invalid-feedback d-block"> {{ errors[0] }}</div>
                                </validation-provider>
                                <label class="d-block border-top">
                                    <validation-provider rules="accepted" v-slot="{ errors }">
                                    <div class="row py-3 align-items-center">
                                        <div class="col-1">
                                            <input type="checkbox" id="check" name="check" v-model="checkout.agree" value="accepted">
                                        </div>

                                        <div class="col 9">Ik aanvaard de algemene voorwaarden

                                        </div>
                                    </div>
                                        <div class="invalid-feedback d-block"> {{ errors[0] }}</div>
                                    </validation-provider>

                                </label>
                            </div>
                        </div>

                        </div>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="col-12">
                        <div class="form-group">
                            <button class="site-btn w-100 text-center" >Bestelling afronden</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </form>
        </ValidationObserver>
    </div>
    </div>
</template>

<script>
const geolib = require('geolib');
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';




import { required, email, is } from 'vee-validate/dist/rules';
import Accepted from './rules/Accepted';
import SearchBar from './parts/SearchBar';

extend('accepted', {
    validate: (value) => {
        console.log(value)
        return value;
    },
    message: 'Gelieve de voorwaarden te aanvaarden.'
});
extend('required', {
    ...required,
    message: 'Dit veld is verplicht.'
});
extend('email', {
    ...email,
    message: 'Gelieve een geldig e-mailadres in te geven'
});

export default {
    data() {

        return {
            checkout: {
                name: '',
                first_name: "",
                email: "",
                phone: "",
                address_street: "",
                address_housenumber: "",
                address_addition: "",
                address_zip: "",
                address_city: "",
                address_country: "",
                dealerID: '',
                webshopID: '',
                deliveryMethod: 'ship',
                paymentMethod: '',
                agree: '',

            },
            dealers: [],
            sortedDealers: [],
            paymentMethods: [],
            showProducts: false,
        }
    },
    beforeMount() {

        this.fetchData();


    },
    mounted() {
        if (sessionStorage.getItem('kanters_checkout')) {
            this.checkout = sessionStorage.getItem('kanters_checkout');
        }
     },
    components: {
        ValidationProvider,
        ValidationObserver,
        SearchBar
    },
    computed: {

        cart() {
            return this.$store.state.StoreCart
        },
        cartTotals() {
            return this.$store.getters.totals
        },



    },
    watch: {
      checkout(newCheckout) {
          console.log(newCheckout)
          sessionStorage.setItem('kanters_checkout', newCheckout);
      }
    },
    methods: {
        setShipping() {

            this.$store.commit('SET_shipping', this.checkout.address_country)
        },
        clearCart() {
            this.$store.commit( 'CLEAR_Cart')
        },
        removeFromCart(index) {
            this.$store.commit('REMOVE_Item', index)
        },
        beforeEnter: function (el) {
            el.style.opacity = 0
            el.style.height = 0
        },

        enter: function (el, done) {
            var delay = el.dataset.index * 150
            setTimeout(function () {
                Velocity(
                    el,
                    {opacity: 1, height: '1.6em'},
                    {complete: done}
                )
            }, delay)
        },
        leave: function (el, done) {

            var delay = el.dataset.index * 150
            setTimeout(function () {
                Velocity(
                    el,
                    {opacity: 0, height: 0},
                    {complete: done}
                )
            }, delay)

        },
        formatPrice(value) {

            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

        },
        fetchData() {

            var currentUrl = window.location.origin;

            this.$http.get(this.api_url("/webshop") +'?domain='+ encodeURIComponent(currentUrl)  )
                .then((response) => {
                    //context.commit("products",response.data.products) //categories will be run from mutation
                    // this.websiteMenuItems = response.data

                    this.checkout.webshopID = response.data.webshop.id;
                    this.checkout.dealerID = response.data.webshop.dealer_id;



                })

            this.$http.get( this.api_url("/dealers"))

                .then((response)=>{

                    this.dealers = response.data.dealers

                })

                .catch(()=>{

                    console.log("Error fetching dealers........")

                })
            this.$http.get(this.api_url("/mollie/payment-methods"))
                .then((response)=>{

                    this.paymentMethods = response.data.paymentMethods

                })

                .catch(()=>{

                    console.log("Error fetching dealers........")

                })

        },
        findDealers() {
            this.setShipping();
            if(this.checkout.address_street != "" && this.checkout.address_zip != "" && this.checkout.address_city != ""  ) {
                let address = this.checkout.address_street
                    + " " + this.checkout.address_housenumber
                    + " " + this.checkout.address_zip
                    + " " + this.checkout.address_city;
                var config = {
                    headers: {'Access-Control-Allow-Origin': '*'}
                };
                this.$http.get(this.api_url("/location-by-address")+'?address='+ address, config)
                    .then((response)=>{

                       console.log(response)
                        this.sortedDealers =  geolib.orderByDistance(response.data, this.dealers)

                    })

                    .catch(()=>{

                        console.log("Error fetching adsress........")

                    })
            }
        },

        inputFocus(event) {
            event.target.closest('.input-container').classList.add('focused');
        },
        inputBlur(event) {
            event.target.closest('.input-container').classList.remove('focused');
            if (event.target.value != "") {
                event.target.closest('.input-container').classList.add('filled');
            } else {
                event.target.closest('.input-container').classList.remove('filled');
            }
        }
        ,
        submit() {
            var data = {

                name: this.checkout.name,
                first_name: this.checkout.first_name,
                email: this.checkout.email,
                phone: this.checkout.phone,
                street: this.checkout.address_street,
                number: this.checkout.address_housenumber,
                addition: this.checkout.address_addition,
                zip: this.checkout.address_zip,
                city: this.checkout.address_city,
                country: this.checkout.address_country,
                dealer_id: this.checkout.dealerID,
                webshop_id: this.checkout.webshopID,
                payment_method: this.checkout.paymentMethod,
                delivery_method: this.checkout.deliveryMethod,
                cartcontent: this.cart,
                _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }

            this.$http.post(this.api_url("/order/create"), data)
            .then(function (response) {
                window.location.href = response.data.paymentUrl;
            })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
}
</script>
<style lang="scss">

.remove-from-cart {
    cursor: pointer;
    text-align: center;
    img {
        width: 12px;
    }
}



</style>
