/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from "vue";

require('./bootstrap');
require('select2');
 require('datatables');
//require('tokenize2');

const geolib = require('geolib');
const fuzzysort = require('fuzzysort');
import axios from 'axios'
//import ProductList from "./components/ProductList";


console.log(geolib.orderByDistance({ latitude: 51.515, longitude: 7.453619 }, [
    { latitude: 52.516272, longitude: 13.377722 },
    { latitude: 51.518, longitude: 7.45425 },
    { latitude: 51.503333, longitude: -0.119722 },
]))

window.Vue = require('vue');
import VueFuse from 'vue-fuse'
Vue.use(VueFuse)
Vue.prototype.$http = axios
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

 const files = require.context('./', true, /\.vue$/i)
 files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))



/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//const app = new Vue({
  //  el: '#app',
//});
//Vue.component(ProductList)
//if (document.getElementById('product-list')) {

    const  productList = new Vue({
        el: "#product-list",
    });

//}




$('.file-select').change(function(e) {
    handleImageSelect(e);
})

function handleImageSelect(evt) {
    var files = evt.target.files;
    if (typeof (FileReader) != "undefined") {
        for (var i = 0, f; f = files[i]; i++) {

            if (!f.type.match('image.*')) {
                continue;
            }
            var reader = new FileReader();
            reader.onload = (function (theFile) {
                return function (e) {
                    if(evt.target.getAttribute('data-thumb-type') === 'img') {
                       var img =  $('<img src="' + e.target.result + '" class="w-100">');
                        $('#' + evt.target.getAttribute('data-thumb-container-id')).html(img);
                    } else {
                        //var span = document.createElement('div');
                        var span  = $(['<span style="background-image: url(\'', e.target.result, '\')"></span>'].join(''));
                        $('#' + evt.target.getAttribute('data-thumb-container-id')).prepend(span)
                        //document.getElementById(evt.target.getAttribute('data-thumb-container-id')).insertBefore(span, null);
                    }

                };

            })(f);
            reader.readAsDataURL(f);
        }
    } else {
        alert("Your browser does not support these image app... Please upgrade your browser!");
    }
}
$(document).ready(function () {
    $('select:not(.multiple)').select2();
     var table = $('table.dt').DataTable({
        "bStateSave": true,
         dom: 'Bfrtip',
        language: {
            decimal: "",
            emptyTable: "Er zijn momenteel geen items om weer te geven",
            info: "_START_ - _END_ van _TOTAL_",
            infoEmpty: "Toont 0 tot 0 van 0 items",
            infoFiltered: "(gefilterd uit _MAX_ resultaten)",
            infoPostFix: "",
            thousands: ".",
            lengthMenu: "Show _MENU_ entries",
            loadingRecords: "Laden...",
            processing: "Verwerken...",
            search: "Zoeken:",
            zeroRecords: "Geen items gevonden.",
            paginate: {
                first: "<<",
                last: ">>",
                next: ">",
                previous: "<"
            },
            aria: {
                "sortAscending": ": Sorteer aflopend",
                "sortDescending": ": Sorteer oplopend"
            }
        },
         buttons: [
             'excel', 'pdf',
         ]

    });
    // $.fn.dataTable.ext.search.push(
    //     function( settings, data, dataIndex ) {
    //         var searchval = $('input.fuzzy').val();
    //         if (searchval == '') {
    //             return true;
    //         }
    //         var results = fuzzysort.go(searchval, data)
    //         console.log(results.length);
    //         if ( results.length > 0  )
    //         {
    //             console.log('show row');
    //             return true;
    //         }
    //         console.log('hide row')
    //         return false;
    //     }
    // );

    // $('<input type="text" class="fuzzy">').appendTo('.dataTables_filter');
    // $('.dataTables_filter input[search]').hide()
    $('.fuzzy').keyup(function(e) {
        table.draw();
    })



})

