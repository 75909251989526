<template>
    <div class="flex-grow-1">
        <section class="home-header px-4">


        <div class="container py-5 ">
            <div class="row align-items-center justify-content-between">
                <div class="col-lg-6" v-html="variables['header-content']">

                </div>
                <div class="col-lg-6 d-block text-center">
                    <img :src="webshop.logo_header" class="inline">
                </div>


            </div>
        </div>
    </section>
        <section class="top-products py-5">
            <div class="container">
            <div class="row">
                <div class="col-12 pt-5 pb-4">
                    <h2>Topproducten</h2>
                </div>
            </div>
            <div class="row mb-5">
                <div v-for="(product,index) in products"  class="col-lg-3 mb-5 list-complete-item" v-bind:key="product.id" v-bind:data-index="index">
                    <router-link :to="{name: 'product', params: {category: null,  subcategory: null, slug: product.slug}}" class="product-item">
                        <div class="img-container">
                            <img :src="product.featured_image" class="w-100" alt="">
                        </div>
                        <div class="product-info">
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <strong>{{ product.brand }}</strong><br>
                                    <strong>{{ product.name}}</strong><br>
                                    {{ product.short_description }}<br>
                                    <span class="price">&euro; {{ formatPrice(product.private_customer_price) }}</span>
                                </div>
                                <!--                                    <div class="col-12">-->
                                <!--                                        <span  class="site-btn" @click.prevent="addToCart(product)" >Toevoegen</span>-->
                                <!--                                    </div>-->
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="col-lg-3 mb-5">
                    <router-link :to="{name: 'webshop'}" class="webshop-link d-flex justify-content-center align-items-center">
                        <div>
                            Bekijk meer
                        </div>
                    </router-link>
                </div>
            </div>
            </div>
        </section>
    </div>

</template>

<script>

export default {
    data ()  {
        return {
            products: [],
            webshop: {},
            variables: {}
        }
    },
    beforeMount() {

        this.fetchData();


    },
    components: {

    },
    methods: {
        fetchData() {
            var currentUrl = window.location.origin;

            this.$http.get(this.api_url("/webshop") +'?domain='+ encodeURIComponent(currentUrl)  )

                .then((response)=>{

                    this.webshop = response.data.webshop
                    this.variables = response.data.variables
                    this.initialSearch()

                })

                .catch(()=>{

                    console.log("Error........")

                })
            this.$http.get( this.api_url("/home-dealer-products") + '?limit=3')

                .then((response)=>{

                    this.products = response.data.products
                    this.initialSearch()

                })

                .catch(()=>{

                    console.log("Error........")

                })

        },
        nltobr(str) {

            return str.replace(/\n/g, '<br />')

        },
        addToCart(product) {

            this.$store.commit('ADD_Item', product)
            this.$toastr('success', 'Het product is toegevoed aan je winkelmandje', 'cart')
        },
        formatPrice(value) {

            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

        },
    },
    computed: {

    },
    created: function() {

    }


}
</script>
<style >
section.home-header {
    background-color: var(--header-bg-color);
    color: var(--header-text-color);


}


h1 {
    max-width: 495px;
    font-family: var(--font);
    font-size: 40px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
}

h2 {
    max-width: 495px;
    font-family: var(--font);
    font-size: 25px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
}
.webshop-link {
    height: 100%;
    background-color: var(--header-bg-color);
    color: var(--heading-color);
    font-size:22px;
    text-decoration: none !important;
    transition: all 300ms ease;
    padding: 10px;
}
.webshop-link:hover {
    background-color: var(--main-color);
    color: white;

}
</style>
