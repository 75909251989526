<template>
    <div class="row my-5">
        <div class="col-12">
            <h1 class="d-block text-center" style="font-size: 22px">Scan een QR-code van een product.</h1>
            <div v-if="error !== ''">
                <div>{{ error }}</div>
                <div>Je kan de QR-code scannen met een QR-code app op het toestel.</div>
            </div>
            <div class="row mt-3 justify-content-center">
                <div class="col-8">
                    <qrcode-stream @decode="onDecode" @init="onInit" />
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
export default {
    data ()  {
        return {
           qrCode: '',
            product: {},
            error: ''

        }
    },
    components: {
        QrcodeStream,
        QrcodeDropZone,
        QrcodeCapture
    },
    methods: {
        onDecode (decodedString) {
            window.location.href = decodedString;
        },
        async onInit (promise) {
            try {
                await promise
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    this.error = "De applicatie heeft geen toestemming voor het gebruik van de camera."
                } else if (error.name === 'NotFoundError') {
                    this.error = "De applicatie kan de camera op het toestel niet vinden."
                } else if (error.name === 'NotSupportedError') {
                    this.error = "Geen beveiligde verbinding."
                } else if (error.name === 'NotReadableError') {
                    this.error = "Wordt de camera reeds gebruikt door een andere app?"
                } else if (error.name === 'OverconstrainedError') {
                    this.error = "Geen camera beschikbaar op het toestel."
                } else if (error.name === 'StreamApiNotSupportedError') {
                    this.error = "Geen camera beschikbaar op het toestel."
                }
            }
        }
    },
    computed: {
        /* getAllProducts(){ //final output from here
             return this.$store.getters.getProductsFormGetters;
         },*/

    },
    created: function() {

    }


}
</script>
