<template>
    <div class="flex-grow-1">
        <search-bar></search-bar>
        <div class="container pb-5">
            <div class="row pt-4 justify-content-between">
                <div class="col-lg-3 pr-5">
                    <div class="row">
                        <div class="col-12">
                            <div class="block">
                                <div class="form-group">
                                    <label for="order" class="text-uppercase font-weight-bold">{{ __('webshop.Sorteren op') }}</label>
                                    <select name="order" v-model="order" class="form-control" id="order">
<!--                                        <option value="top">{{ __('Topproducten') }}</option>-->
                                        <option value="price-asc">{{ __('webshop.Prijs, oplopend') }}</option>
                                        <option value="price-desc">{{ __('webshop.Prijs, aflopend') }}</option>
                                        <option value="newest">{{ __('webshop.Nieuwste') }}</option>
                                        <option value="oldest">{{ __('webshop.Oudste') }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mt-4 mb-4">
                            <div class="border py-3">

                                <BrandCategoryFilter></BrandCategoryFilter>
                                <CategoryFilter></CategoryFilter>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9">
                    <transition-group
                        name="list-complete"
                        tag="div"
                        class="row"

                    >
                        <product-list-item v-for="(product,index) in  category.topproducts" v-if="index < (page * itemsPerPage ) && index > ((page-1)*itemsPerPage-1)"  v-bind:key="product.id" v-bind:data-index="index" :product="product" :category="category" label="Topproduct"></product-list-item>

                    </transition-group>
                        <transition-group
                            name="list-complete"
                            tag="div"
                            class="row"

                        >

                            <product-list-item v-for="(product,index) in sorted" v-if="index < (page * itemsPerPage ) && index > ((page-1)*itemsPerPage-1)"  v-bind:key="product.id" v-bind:data-index="index" :product="product" :category="category"></product-list-item>
                        </transition-group>

                    <div class="row justify-content-center" v-if="results != []">

                        <div class="col-auto">
                            <ul class="pagination">
                                <li v-if="pageCount > 1 && page > 1" @click="toPage(page -1)">
                                    <
                                </li>
                                <li v-if="page > 6 && pageCount > 10">
                                    ...
                                </li>
                                <li v-for="pageNumber in pageCount" :key="pageNumber"
                                    v-if="(pageNumber > (page-6)
                                && (pageNumber < (page+6))
                                || (pageNumber > (page-6) && pageNumber < 11)
                                || (pageNumber < (page+6) && pageNumber > pageCount - 10))"
                                    :class="(pageNumber === page) ? 'active-page' : ''" @click="toPage(pageNumber )">
                                    {{ pageNumber  }}
                                </li>
                                <li v-if="page < (pageCount -5 ) && pageCount > 10">
                                    ...
                                </li>
                                <li v-if="pageCount > 1 && page < pageCount" @click="toPage(page +1)">
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BrandCategoryFilter from "./parts/BrandCategoryFilter"
import CategoryFilter from "./parts/CategoryFilter"
import SearchBar from './parts/SearchBar';
import ProductListItem from "./parts/ProductListItem.vue";

export default {
    data ()  {

        return {

            keyword:        (this.$route.query.search) ? this.$route.query.search : '',
            order:          "top",
            page:           1,
            pageCount:      0,
            itemsPerPage:   12,
            category:       {},
            results:        '',
            error:          ''

        }

    },
    beforeMount() {

        this.fetchData();


    },
    components: {
        ProductListItem,
        CategoryFilter,
        BrandCategoryFilter,
        SearchBar
    },
    watch: {
        // call again the method if the route changes
        '$route': 'fetchData'
    },
    methods: {
        fetchData() {

             this.$http.get( this.api_url("/category-by-slug?locale=")+ window._locale, { params: { slug: this.$route.params.slug}})

                .then((response)=>{

                    this.category = response.data.category
                    console.log(response.data.languageSlugs)
                    this.$root.title = this.category.name;
                    this.initialSearch()

                })

                .catch(()=>{

                    console.log("Error........")

                })
        },
        nltobr(str) {

            return str.replace(/\n/g, '<br />')

        },
        runSearch () {

            //this.$router.push({ query: { ...this.$route.query, search: this.keyword } });
            if (this.keyword) {

                this.UpdateQueryString('search', this.keyword)

                this.$search(this.keyword, ((this.category.parent_id ) ? this.category.products : this.category.childproducts), {

                    keys: ['name', 'brand', 'keywords'],
                    'threshold': 0.4

                }).then(result => {

                    this.results        = result
                    this.resultCount    = result.length;
                    this.pageCount      = Math.ceil(this.resultCount / this.itemsPerPage)
                    this.toPage(1);
                    this.$router.push({name: 'home', query: {search: this.keyword}})

                })

            } else {

                this.results        = (this.category.parent_id ) ? this.category.products : this.category.childproducts
                this.UpdateQueryString('search', this.keyword)
                this.resultCount    = this.results.length;
                this.pageCount      = Math.ceil(this.resultCount / this.itemsPerPage)
                this.$router.push({name: 'home', query: {search: this.keyword}})

            }


        },
        initialSearch () {

            if (this.keyword) {

                this.UpdateQueryString('search', this.keyword)

                this.$search(this.keyword, ((this.category.products.length >0 ) ? this.category.products : this.category.childproducts), {

                    keys: ['name', 'brand', 'keywords'],
                    'threshold': 0.4

                }).then(result => {

                    this.results        = result
                    this.resultCount    = result.length;
                    this.pageCount      = Math.ceil(this.resultCount / this.itemsPerPage)
                    this.toPage(1);


                })

            } else {

                this.results        = (this.category.products.length >0 ) ? this.category.products : this.category.childproducts
                this.UpdateQueryString('search', this.keyword)
                this.resultCount    = this.results.length;
                this.pageCount      = Math.ceil(this.resultCount / this.itemsPerPage)
                this.toPage(1);

            }


        },

        toPage(pageNumber) {

            this.page = pageNumber;
            this.UpdateQueryString('page', this.page)
            window.scrollTo({top: 0,left: 0, behavior: 'smooth'});

        },
        addToCart(product) {

            this.$store.commit('ADD_Item', product)
            this.$toastr('success', 'Het product is toegevoegd aan je winkelmandje', 'cart')
        },
        formatPrice(value) {

            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

        },

        UpdateQueryString(key, value) {

            var queryParams = new URLSearchParams(window.location.search);
            queryParams.set(key, value);
            history.replaceState(null, null, "?"+queryParams.toString());

        }

    },
    computed: {
        sorted() {
            let sorted = this.results

            if(sorted) {

                if (this.order == "price-asc") {
                    return sorted.sort(function (a, b) {
                        return (a.private_customer_price > b.private_customer_price) ? 1: -1 ;
                    })
                }
                else if (this.order == "price-asc") {
                    return sorted.sort(function (a, b) {
                        return (a.private_customer_price > b.private_customer_price) ? 1: -1 ;
                    })
                } else if (this.order == "top") {
                    sorted = sorted.sort(function (a, b) {
                        return (a.private_customer_price > b.private_customer_price) ? 1: -1 ;
                    })
                    return sorted.sort(function (a, b) {
                        return (a.is_top_product > b.is_top_product) ? 1: -1 ;
                    })
                } else if (this.order == "newest") {
                    return sorted.sort(function (a, b) {
                        return (a.id < b.id) ? 1: -1 ;
                    })
                } else if (this.order == "oldest") {
                    return sorted.sort(function (a, b) {
                        return (a.id > b.id) ? 1: -1 ;
                    })
                } else {
                    return sorted.sort(function (a, b) {
                        return (a.is_top_product < b.is_top_product) ? 1: -1 ;
                    })
                }
            }
            return
        }

    },
    created: function() {

    }


}
</script>
<style lang="scss">
.price {
    font-size: 24px;
    font-weight: 700;
    color: #0092c9;
}
.video-container {
    position:relative;
    padding-bottom:56.25%;
    padding-top:30px;
    height:0;
    overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}




.product-info {
    color: #000000;
    text-decoration: none;
}



body {
    background-color: white;
}
a.btn {
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #0092c9;
    color: #ffffff;
}
</style>
