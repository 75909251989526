<template>
    <div class="container">
        <div class="row  my-4">
            <div class="col-6">
                <div class="form-group">
                    <input type="text" v-model="keyword" class="search form-control" placeholder="Zoek hier door de producten." @keyup="runSearch">
                </div>
            </div>
        </div>
        <div class="row justify-content-center">

            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                        <th></th>
                        <th>Artikel</th>
                        <th>Artikelnummer</th>
                        <th>Categorie</th>
                        <th>Prijs</th>
                        <th>Promo?</th>
                        <th style="width:90px"></th>
                        </thead>
                        <tbody>

                        <tr v-for="(product,index) in results" v-if="index < (page * itemsPerPage ) && index > ((page-1)*itemsPerPage-1)">
                            <td class="image-preview" style="margin-left:0; margin-right:0;"><span :style="'width: 50px; height: 50px;background-size:cover;  background-image: url(\'' + product.featured_image+ '\');'"></span></td>
                            <td>{{ product.brand }}<br>{{ product.name }}</td>
                            <td>{{ product.sku }}</td>
                            <td><a href=""></a>{{ (product.category) ? product.category.name : 'Geen categorie' }}</td>
                            <td>&euro; {{ product.price }}</td>
                            <td>{{ (product.in_promo == 1) ? "JA" : "NEE" }}</td>
                            <td class="text-right">
                                <a :href="'/administrator/producten/detail/' + product.id" >
                                    <img src="/img/edit-tools.png" alt="">
                                </a>
                                <a href="#" data-toggle="modal"  :data-target="'#toggle-delete-modal-' +product.id" class="toggle-delete-modal ml-3">
                                    <img src="/img/delete-tools.png" alt="">
                                </a>
                                <div class="modal fade text-left" :id="'toggle-delete-modal-' + product.id" tabindex="-1" role="dialog">
                                    <div class="modal-dialog modal-dialog-centered " role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title">Verwijderen?</h5>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <p>Ben je zeker dat je <strong>{{ product.brand }} {{ product.name }}</strong> wil verwijderen?</p>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="t2" data-dismiss="modal">Annuleren</button>
                                                <a :href="'/administrator/producten/delete/' + product.id" class="t1">Verwijderen</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row justify-content-center" v-if="results != []">

                <div class="col-auto">
                    <ul class="pagination">
                        <li v-if="pageCount > 1 && page > 1" @click="toPage(page -1)">
                            <
                        </li>
                        <li v-if="page > 6 && pageCount > 10">
                            ...
                        </li>
                        <li v-for="pageNumber in pageCount" :key="pageNumber"
                            v-if="(pageNumber > (page-6)
                                && (pageNumber < (page+6))
                                || (pageNumber > (page-6) && pageNumber < 11)
                                || (pageNumber < (page+6) && pageNumber > pageCount - 10))"
                            :class="(pageNumber === page) ? 'active-page' : ''" @click="toPage(pageNumber )">
                            {{ pageNumber  }}
                        </li>
                        <li v-if="page < (pageCount -5 ) && pageCount > 10">
                            ...
                        </li>
                        <li v-if="pageCount > 1 && page < pageCount" @click="toPage(page +1)">
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                keyword: '',
                products: [],
                results: [],
                order:          "price-asc",
                page:           1,
                pageCount:      0,
                itemsPerPage:   12,
                resultCount:    0,
            }
        },
        mounted() {
            let queryString = window.location.search;
            let urlParams = new URLSearchParams(queryString);
            this.keyword = urlParams.get('search') ?? ''
            this.page = urlParams.get('page') ?? 1

            this.fetchData()
        },
        methods: {
            fetchData() {

                this.$http.get( '/api/products?all=1')

                    .then((response)=>{

                        this.products = response.data.products
                        this.initialSearch();

                    })

                    .catch(()=>{

                        console.log("Error........")

                    })
            },
            runSearch () {

                //this.$router.push({ query: { ...this.$route.query, search: this.keyword } });
                if (this.keyword) {

                    this.UpdateQueryString('search', this.keyword)

                    this.$search(this.keyword, this.products, {

                        keys: ['name', 'brand','sku', 'keywords'],
                        'threshold': 0

                    }).then(result => {

                        this.results        = result
                        this.resultCount    = result.length;
                        this.pageCount      = Math.ceil(this.resultCount / this.itemsPerPage)
                        this.toPage(1);


                    })

                } else {

                    this.results        = this.products
                    this.UpdateQueryString('search', this.keyword)
                    this.resultCount    = this.results.length;
                    this.pageCount      = Math.ceil(this.resultCount / this.itemsPerPage)
                    this.toPage(1);

                }


            },
            initialSearch () {

                if (this.keyword) {

                    this.UpdateQueryString('search', this.keyword)

                    this.$search(this.keyword, this.products, {

                        keys: ['name', 'brand', 'keywords'],
                        'threshold': 1

                    }).then(result => {

                        this.results        = result
                        this.resultCount    = result.length;
                        this.pageCount      = Math.ceil(this.resultCount / this.itemsPerPage)
                        //this.toPage(1);


                    })

                } else {

                    this.results        = this.products
                   // this.UpdateQueryString('search', this.keyword)
                    this.resultCount    = this.results.length;
                    this.pageCount      = Math.ceil(this.resultCount / this.itemsPerPage)
                    //this.toPage(1);

                }


            },
            toPage(pageNumber) {

                this.page = pageNumber;
                this.UpdateQueryString('page', this.page)
                window.scrollTo({top: 0,left: 0, behavior: 'smooth'});

            },
            UpdateQueryString(key, value) {

                var queryParams = new URLSearchParams(window.location.search);
                queryParams.set(key, value);
                history.replaceState(null, null, "?"+queryParams.toString());

            }
        }
    }
</script>
