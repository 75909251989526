var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row mt-5" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.keyword,
                expression: "keyword",
              },
            ],
            attrs: {
              type: "text",
              placeholder:
                "Zoek hier je producten op merk, naam of omschrijving.",
            },
            domProps: { value: _vm.keyword },
            on: {
              keyup: _vm.runSearch,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.keyword = $event.target.value
              },
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mt-5" }, [
      _vm.results.length > 0
        ? _c("div", { staticClass: "col-6 mb-4" }, [
            _vm._v("\n            pagina: " + _vm._s(_vm.page) + "\n        "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.results.length > 0
        ? _c("div", { staticClass: "col-6 text-right mb-4" }, [
            _vm._v(
              "\n            Aantal resultaten: " +
                _vm._s(_vm.results.length) +
                "\n        "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "transition-group",
            { attrs: { name: "list", tag: "div" } },
            _vm._l(_vm.results, function (product, index) {
              return _c("div", { key: product.id, staticClass: "mb-4" }, [
                index < _vm.page * _vm.itemsPerPage &&
                index > (_vm.page - 1) * _vm.itemsPerPage - 1
                  ? _c("div", { staticClass: "product-list-item" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-2 text-center" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "h-100 w-100 d-flex align-items-center justify-content-center",
                              staticStyle: { "background-color": "#ffffff" },
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  "max-width": "100%",
                                  "max-height": "150px",
                                },
                                attrs: {
                                  src: product.featured_image
                                    ? product.featured_image
                                    : "/storage/no-image.png",
                                  alt: product.name,
                                },
                              }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-10 p-3" }, [
                          _c(
                            "div",
                            { staticClass: "row h-100 align-items-center" },
                            [
                              _c("div", { staticClass: "col py-4" }, [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(product.brand) +
                                      " " +
                                      _vm._s(product.name)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "mt-2" }, [
                                  _vm._v(
                                    "\n                                            " +
                                      _vm._s(product.short_description) +
                                      "\n                                        "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              product.price == product.dealer_price
                                ? _c("div", { staticClass: "col-auto price" }, [
                                    _vm._v(
                                      "\n                                        €" +
                                        _vm._s(_vm.formatPrice(product.price)) +
                                        "\n                                    "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              product.price != product.dealer_price
                                ? _c("div", { staticClass: "col-auto price" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "old-price",
                                        staticStyle: {
                                          "text-decoration": "line-through",
                                          "font-size": "16px",
                                          color: "#695446",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " €" +
                                            _vm._s(
                                              _vm.formatPrice(product.price)
                                            )
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                                        €" +
                                        _vm._s(
                                          _vm.formatPrice(product.dealer_price)
                                        ) +
                                        "\n                                    "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-auto pr-5 form-group" },
                                [
                                  _c(
                                    "form",
                                    {
                                      attrs: {
                                        action: "/winkelmand/toevoegen",
                                        method: "post",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        attrs: { type: "hidden", name: "id" },
                                        domProps: { value: product.id },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "form-group qty-group" },
                                        [
                                          _c(
                                            "label",
                                            { attrs: { for: "qty" } },
                                            [_vm._v("Aantal")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "d-inline-block qty-down",
                                            },
                                            [_vm._v("-")]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            staticClass:
                                              "form-control qty-field d-inline-block",
                                            staticStyle: {
                                              height: "43px",
                                              "text-align": "center",
                                            },
                                            attrs: {
                                              type: "number",
                                              id: "qty",
                                              min: "1",
                                              step: "1",
                                              value: "1",
                                              name: "qty",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "px-3 d-inline-block qty-up",
                                            },
                                            [_vm._v("+")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "site-btn w-100",
                                          attrs: { type: "submit" },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: _vm.asset(
                                                "/storage/cart-icon.png"
                                              ),
                                              alt: "",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm.results != []
      ? _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-auto" }, [
            _c(
              "ul",
              { staticClass: "pagination" },
              [
                _vm.pageCount > 1 && _vm.page > 1
                  ? _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.toPage(_vm.page - 1)
                          },
                        },
                      },
                      [_vm._v("\n                    <\n                ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.page > 6 && _vm.pageCount > 10
                  ? _c("li", [
                      _vm._v("\n                    ...\n                "),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.pageCount, function (pageNumber) {
                  return (pageNumber > _vm.page - 6 &&
                    pageNumber < _vm.page + 6) ||
                    (pageNumber > _vm.page - 6 && pageNumber < 11) ||
                    (pageNumber < _vm.page + 6 &&
                      pageNumber > _vm.pageCount - 10)
                    ? _c(
                        "li",
                        {
                          key: pageNumber,
                          class: pageNumber === _vm.page ? "active-page" : "",
                          on: {
                            click: function ($event) {
                              return _vm.toPage(pageNumber)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(pageNumber) +
                              "\n                "
                          ),
                        ]
                      )
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm.page < _vm.pageCount - 5 && _vm.pageCount > 10
                  ? _c("li", [
                      _vm._v("\n                    ...\n                "),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.pageCount > 1 && _vm.page < _vm.pageCount
                  ? _c(
                      "li",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.toPage(_vm.page + 1)
                          },
                        },
                      },
                      [_vm._v("\n                    >\n                ")]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }