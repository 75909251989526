<template>
    <section id="search-bar" class="mb-5 search-bar-dealer">
        <div class="container search-bar-top">
            <div class="row py-5 align-items-center">
                <div class="col-12 col-lg-5">
                    <div class="input-group search-group">
                        <div class="input-group-prepend">
                            <i class="fal fa-search pt-1"></i>
                        </div>
                        <input type="text" v-model="keyword" class="search form-control" placeholder="Product of merk." @input='e => keyword = e.target.value' @keyup.stop="runSearch">
                    </div>

                </div>
                <div class="col-12 mt-3 mt-lg-0 col-lg-7">
                    <div class="row justify-content-end" style="font-size:1.2rem">
                        <div class="col-lg-auto">
                            <img src="/img/truck_svg.svg" class="mr-3" alt="" style="height: 16px; width:20px"> <span style="color: #ffffff; display: inline-block; padding-top: 5px; font-size:1rem;">Gratis verzending vanaf €50</span>
                        </div>
                        <div class="col-lg-auto">
                            <img src="/img/leaf_svg.svg" class="mr-3" alt="" style="height: 16px; width:20px"> <span style="color: #ffffff; display: inline-block; padding-top: 5px; font-size:1rem;">Met natuurlijke producten</span>
                        </div>
                        <div class="col-lg-auto">
                            <img src="/img/medal_svg.svg" class="mr-3" alt="" style="height: 18px; width:20px"> <span style="color: #ffffff; display: inline-block; padding-top: 5px; font-size:1rem;">Top service</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data ()  {

        return {

           keyword: (this.$route.query.search) ? this.$route.query.search : '',
            timeout: null
        }

    },
    async mounted() {

        await this.$http.get( this.api_url("/categories"))

            .then((response)=>{

                this.categories = response.data.categories

            })

            .catch(()=>{

                console.log("Error........")

            })


    },
    components: {

    },
    methods: {

        runSearch () {
            var self = this
            //this.$router.push({ query: { ...this.$route.query, search: this.keyword } });
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            this.timeout = setTimeout(function() {

            if (this.keyword) {

                this.UpdateQueryString('search', self.keyword)
                    if(self.$route.matched.some(({ name }) => name === 'webshop')) {
                        window.location.reload();
                    } else {

                        self.$router.push({name: 'webshop', query: {search: self.keyword,t: new Date().getTime(),}})

                    }

            } else {
                self.UpdateQueryString('search', self.keyword)
                if(self.$route.matched.some(({ name }) => name === 'webshop')) {
                    window.location.reload();
                } else {

                    self.$router.push({name: 'webshop', query: {search: self.keyword,t: new Date().getTime(),}})

                }

            }
            }, 1500);


        },
        UpdateQueryString(key, value) {

            var queryParams = new URLSearchParams(window.location.search);
            queryParams.set(key, value);
            history.replaceState(null, null, "?"+queryParams.toString());

        }

    },
    computed: {


    },
    created: function() {

    }


}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
    .search.form-control {
        max-width: 300px !important;
    }
}
 ul {
     list-style-type: none;
     padding-left: 0;
     li {
         display: block;
         a {
             padding: 4px 0;
             color: #000000;
             text-decoration: none;
             text-transform: uppercase;
             &:hover, &.active {
                 color: #0092C9;
             }
             &:not(.active) ~ ul {
                 display: none;
             }
         }
         ul {
             li{
                 padding-left: 10px;
                 a {
                     text-transform:none;

                 }
             }
         }

     }
 }
</style>
