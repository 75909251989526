<template>
    <div class="d-flex flex-column justify-content-between" style="min-height: 100vh">
        <header>
            <div class="container px-2">
                <div class="navbar px-0">
                <div class="row align-items-center justify-content-between w-100">
                    <div class="col-7 text-center text-lg-left col-lg-4 pr-0">
                        <a class="navbar-brand" href="https://www.kanters.be">
                            <img src="/img/Logo-Kanters_bruin.png" alt="Kanters Haar- en Nagelstyling">
                        </a>
                    </div>
                    <div class="col-5 col-lg-8 pr-0">
                        <div class="tm-header-mobile d-lg-none">

                            <div  show-on-up="" style="">

                                <div class="uk-navbar-container">
                                    <nav uk-navbar="" class="uk-navbar">




                                        <div class="uk-navbar-right">


                                            <a class="uk-navbar-toggle" href="#tm-mobile" uk-toggle="animation: true" aria-expanded="false">
                                                <div uk-navbar-toggle-icon="" class="uk-icon uk-navbar-toggle-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><rect y="9" width="20" height="2"></rect><rect y="3" width="20" height="2"></rect><rect y="15" width="20" height="2"></rect></svg></div>
                                            </a>
                                            <ul class="navbar-nav flex-row justify-content-end text-center mt-0">


                                                <li class="nav-item col-auto pr-0">
                                                    <router-link :to="{ name: 'cart', }" class="nav-link mini-cart-link">
                                                        <i class="fal fa-shopping-cart" style="font-size: 1.5rem"></i>
                                                        <small v-if="$store.getters.count > 0" class="badge badge-pill badge-dark">{{ $store.getters.count}}</small>
                                                    </router-link>

                                                </li>
                                                <li class="nav-item col-auto pr-0 d-none d-lg-block">
                                                    <img class="wpml-ls-flag" src="https://www.kanters.be/wp-content/plugins/sitepress-multilingual-cms/res/flags/fr.png" alt="Frans">
                                                </li>

                                            </ul>

                                        </div>

                                    </nav>
                                </div>







                            </div><div class="uk-sticky-placeholder" style="height: 100px; margin: 0px;" hidden=""></div>


                        </div>
                        <ul class="navbar-nav flex-row justify-content-end text-center row d-none d-lg-block">

                            <li v-for="(menuItem, index) in websiteMenuItems" class="nav-item col-auto"  :class="'item-'+menuItem.ID" v-if="index !== 5">
                                <a :href="menuItem.url" v-html="menuItem.title" :target="menuItem.target" class="nav-link d-inline-block w-auto">

                                </a>
                            </li>

                            <li class="nav-item col-auto">
                                <a :href="$router.resolve({ name: 'home-loc', params: {locale: $route.params.locale }}).href" class="nav-link d-inline-block  w-auto">
                                    Webshop
                                </a>
                            </li>

<!--                            <li class="nav-item col-auto pr-0" v-if="window._locale == 'nl'">-->
<!--                                <router-link :to="{ locale: 'fr', }" class="nav-link mini-cart-link">-->
<!--                                <img class="wpml-ls-flag" src="https://www.kanters.be/wp-content/plugins/sitepress-multilingual-cms/res/flags/fr.png" alt="Frans">-->
<!--                                </router-link>-->
<!--                            </li>-->
                            <li class="nav-item col-auto pr-0">
                                <router-link :to="{ name: 'cart', }" class="nav-link mini-cart-link">
                                    <i class="fal fa-shopping-cart" style="font-size: 1.5rem"></i>
                                    <small v-if="$store.getters.count > 0" class="badge badge-pill badge-dark">{{ $store.getters.count}}</small>
                                </router-link>

                            </li>
                            <li v-if="$route.params.locale !== 'nl'"  class="nav-item col-auto pr-0" >
                                <a :href="$router.resolve({  params: { locale: 'nl'} }).href" class="nav-link mini-cart-link">
                                    <img class="wpml-ls-flag" src="https://www.kanters.be/wp-content/plugins/sitepress-multilingual-cms/res/flags/nl.png" alt="Nederlands">
                                </a>
                            </li>
                            <li v-if="$route.params.locale !== 'fr'" class="nav-item col-auto pr-0" >
                                <a :href="$router.resolve({  params: { locale: 'fr'} }).href" class="nav-link mini-cart-link">
                                    <img class="wpml-ls-flag" src="https://www.kanters.be/wp-content/plugins/sitepress-multilingual-cms/res/flags/fr.png" alt="Nederlands">
                                </a>
                            </li>


                        </ul>
                    </div>

                </div>

                </div>
<!--                <div class="row py-1">-->
<!--                    <div class="col-12 text-center header-title">-->
<!--                        <h1>{{ $root.title }}</h1>-->
<!--                    </div>-->
<!--                </div>-->
            </div>

        </header>
        <div id="tm-mobile" class="" hidden="">
            <div class="uk-background-default uk-padding uk-text-center">


                <div class="uk-child-width-1-1 uk-grid uk-grid-stack" uk-grid="">    <div>
                    <div class="uk-panel">



                        <ul class="uk-nav uk-nav-default uk-nav-center">

                            <li v-for="(menuItem, index) in websiteMenuItems" class="nav-item col-auto" :class="'item-'+menuItem.ID" v-if="index !== 5">
                                <a :href="menuItem.url"  v-html="menuItem.title" class="nav-link d-inline-block w-auto">
                                </a>
                            </li>


                            <li class="nav-item col-auto">
                                <router-link :to="{ name: 'home', }" class="nav-link d-inline-block  w-auto">
                                    Webshop
                                </router-link>
                            </li>
                            <li class="nav-item col-auto   d-lg-none">
                                <img class="wpml-ls-flag" src="https://www.kanters.be/wp-content/plugins/sitepress-multilingual-cms/res/flags/fr.png" alt="Frans">
                            </li>
                        </ul>
                    </div>
                </div></div>
            </div>
        </div>

            <transition name="slide" mode="out-in"  @beforeLeave="fadeBeforeLeave"
                        @enter="fadeEnter"
                        @afterEnter="fadeAfterEnter">
                <router-view></router-view>
            </transition>


        <footer class="footer">
            <div class="uk-container">

                <div class="tm-grid-expand uk-grid-margin uk-grid" uk-grid="">
                    <div class="uk-grid-item-match uk-flex-middle uk-width-1-2@m uk-first-column">



                        <div class="uk-panel uk-width-1-1">
                            <div class="uk-panel uk-margin"><h2>{{ __('webshop.Onze openingsuren') }}</h2>
                                <p>{{ __('webshop.Ma - Vrij: 09u00 - 17u30' ) }}<br>
                                    {{ __('webshop.Zaterdag en zondag gesloten' ) }}</p></div>
                        </div>



                    </div>

                    <div class="uk-grid-item-match uk-flex-middle uk-width-1-2@m">



                        <div class="uk-panel uk-width-1-1">
                            <div class="uk-panel uk-margin"><ul class="footer-left">
                                <li>Fabriekstraat 104C | 3900 Pelt</li>
                                <li><a href="tel:+3211641289">011/64 12 89</a></li>
                                <li><a href="mailto:info@kanters.be">info@kanters.be</a></li>
                                <li>BE0473 231 821</li>
                            </ul></div><div class="uk-panel uk-margin"><ul class="footer-right">
                            <li><a href="https://kanters.be/algemene-voorwaarden/">{{ __('webshop.Algemene voorwaarden') }}</a></li>
                            <li><a href="https://kanters.be/levertijden-en-verzendkosten/">{{ __('webshop.Levertijden en verzendkosten') }}</a></li>
                            <li><a href="https://kanters.be/retourneren/">{{ __('webshop.Retourneren') }}</a></li>
                        </ul></div><div class="uk-panel uk-margin"><ul class="footer-right" style="top: -20px;">
                            <li>© 2021</li>
                            <li><a href="https://kanters.be//disclaimer/">{{ __('webshop.Disclaimer')  }}</a></li>
                            <li><a href="https://kanters.be//privacy-policy/">{{ __('webshop.Privacy Policy') }}</a></li>
                            <li><a href="https://www.creatic.com" target="_blank" rel="noopener">Creatic.Agency</a></li>
                        </ul></div>
                        </div>



                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
export default {
    data() {
        return {
            prevHeight: 0,
            title: '',
            websiteMenuItems: [],
        }
    },
    beforeMount() {
        this.loadWebsiteMenu();
    },
    methods: {
        fadeBeforeLeave(element) {
            this.prevHeight = getComputedStyle(element).height;
        },
        fadeEnter(element) {
            window.scrollTo({top: 0,left: 0});
            const {height} = getComputedStyle(element);

            element.style.height = "100vh";

            setTimeout(() => {
                element.style.height = height;
            });
        },
        fadeAfterEnter(element) {
            element.style.height = 'auto';
        },
        loadWebsiteMenu() {
            this.$http.get(this.api_url("/wp/menu") + '?locale=' + window._locale)
            .then((response) => {
                //context.commit("products",response.data.products) //categories will be run from mutation
               this.websiteMenuItems = response.data


            })
        }
    }
}
</script>
<style>
.footer ul > li,
.footer ul > li > a{

    font-size: 15px;
    font-style: normal;
    letter-spacing: normal;
    line-height: 22px;
    color: #695446 !important;
}
.footer ul {
    text-align: right;
    list-style-type: none;
    padding-left: 0;
    display: block;
}
.footer-right{top:-12px;position: relative;     color: #695446 !important;}
.footer h2{    color: #695446 !important; font-size: 18px;margin-bottom:0;
    font-weight: 700;
    font-style: normal;
    letter-spacing: normal;
    line-height: 34.47px;
}
.footer p{margin-top:5px;font-size:15px}
.footer {
    background-color: #eae6d9 !important;
    color: #695446 !important;
    padding-top: 24px;
    padding-bottom: 24px;

}
footer ul>li{display: inline-block}
footer ul>li:not(:last-child):after{content:'|';margin-left:5px;margin-right:5px;}

@media(min-width: 959px){
    .footer > div {
        max-width: 1410px;
        margin: 0 auto;
    }
    .footer {
        padding-top: 48px;
        padding-bottom: 48px;
    }
}
@media(max-width:959px){
    .footer{padding-top:40px;padding-bottom:40px;}
    .footer ul {text-align: center}
    .footer>div>div>div:nth-child(1)>div{text-align: center}
}
@media(max-width: 620px){
    .footer ul>li{display:block;     color: #695446 !important;}
    .footer ul>li:after{display: none}
    .footer-right{margin-top:20px;}
}
.uk-navbar-container:not(.uk-navbar-transparent) {
    background: #fff;
}
.uk-navbar-toggle-icon > svg {color: #2d2b2b;}

.uk-nav-default>li>a {
    color: #2d2b2b !important;
    font-weight: 600 !important;
    font-style: normal !important;
    font-size: 15px !important;
    line-height: 22px !important;
    text-transform: uppercase !important;
}

.uk-nav-default>li>a:hover,
.uk-nav-default>li>a:focus {
    color: #695446 !important;
}

.uk-nav-default>li.uk-active>a {
    color: #695446 !important;
}

.uk-navbar-nav>li>a{
    color: #2d2b2b;
    font-family: var(--font);
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: normal;
    text-transform: uppercase;
    line-height: normal;
}

.uk-navbar-container {
    position: relative;
    z-index: 5;
}


@media (max-width: 1030px) {
    .tm-header.uk-visible\@m { display: none !important; }
    .tm-header-mobile.uk-hidden\@m { display: block !important; }
    .tm-toolbar,.page-header,.uk-subnav {display: none;}
    .uk-logo img {max-height: 70px;}
    .home-header > div {height: 300px;}
    .home-header:after{display: none;}
    #tm-mobile > div {
        box-shadow: 0 10px 10px 0px rgba(0,0,0,0.05);
    }
    .uk-navbar-item, .uk-navbar-nav>li>a, .uk-navbar-toggle {min-height: 100px;}
}

</style>
