var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row my-5" }, [
    _c("div", { staticClass: "col-12" }, [
      _c(
        "h1",
        {
          staticClass: "d-block text-center",
          staticStyle: { "font-size": "22px" },
        },
        [_vm._v("Scan een QR-code van een product.")]
      ),
      _vm._v(" "),
      _vm.error !== ""
        ? _c("div", [
            _c("div", [_vm._v(_vm._s(_vm.error))]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "Je kan de QR-code scannen met een QR-code app op het toestel."
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-3 justify-content-center" }, [
        _c(
          "div",
          { staticClass: "col-8" },
          [
            _c("qrcode-stream", {
              on: { decode: _vm.onDecode, init: _vm.onInit },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }