<template>
    <div class="flex-grow-1">
    <search-bar></search-bar>
        <section>
        <transition name="fadeDown" tag="ul">

            <div class="row" v-if="message.display">
                <div class="col-12">
                    <div :class="'alert alert-' + message.type" style="margin-top:-50px">
                        {{ message.content}}

                        <span class="ml-3" style="cursor: pointer" @click.prevent="clearMessage">
                            <span aria-hidden="true">&times;</span>
                        </span>
                    </div>
                </div>
            </div>

        </transition>
        </section>
    <div class="container flex-grow-1">
        <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="block py-3">
                    <div class="row justify-content-between">
                        <div class="col-auto">
                            <router-link :to="{ name: 'brand-category', params: { parent: ((product.brand_category.parent) ? product.brand_category.parent.slug : null) ,slug: product.brand_category.slug }}">
                                <i class="far fa-chevron-left mr-3"></i>  Ga terug
                            </router-link>

                        </div>
                        <div class="col-auto">
                            <router-link :to="{ name: 'home'}">
                                Webshop
                            </router-link> <span class="px-2">/</span>
                            <router-link :to="{ name: 'main-brand-category', params: { slug:((product.brand_category.parent) ? product.brand_category.parent.slug : product.brand_category.slug)  }}">
                                {{ ((product.brand_category.parent) ? product.brand_category.parent.slug : product.brand_category.name) }}
                            </router-link> <span class="px-2">/</span>
                            <router-link v-if="product.brand_category.parent" :to="{ name: 'brand-category', params: { parent:product.brand_category.parent.slug ,slug: product.brand_category.slug }}">
                                 {{ product.brand_category.name }}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <div class="row justify-content-between py-5">
        <div class="col-12" v-if="product">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-4 order-lg-2" >

                            <div class="product-detail-title pb-4">
                    <div class="row">
                        <div class="col-xl-7">

                                <div>{{ product.brand }}</div>
                                <h2>{{ product.name}}</h2>

                        </div>
                        <div class="col-xl-5 text-md-right">
                            <div class="price">
                                &euro; {{ formatPrice( product.private_customer_price) }}
                            </div>
                            <div class="row  justify-content-end mt-3">
                                <div class="col-7">

                                    <div class="form-group qty-group text-left">
                                        <label for="qty">Aantal</label>
                                        <span class="d-inline-block qty-down" @click="qty--">-</span>
                                        <input type="number" id="qty" min="1" step="1" value="1" name="qty" v-model="qty" class="form-control qty-field d-inline-block" style="height: 43px; text-align: center">
                                        <span class="px-3 d-inline-block qty-up" @click="qty++">+</span>
                                    </div>
                                </div>
                                <div class="col-auto pl-0">
                                    <span class="site-btn px-3" @click="addToCart()" style="font-size: 1.2rem"><i class="fal fa-shopping-cart"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                            </div>

                    <div class="row mt-5 mb-4">
                        <div class="col-12" v-html="nltobr(product.description)">

                        </div>
                    </div>
                    <div class="row mt-4"  v-if="product.video">
                        <div class="col-12">
                            <div class="block py-2">
                                <span type="button" class=" video-btn" data-toggle="modal" :data-src="product.video" data-target="#myModal">
                               <span class="open-video" style=" font-size: 1rem; cursor:pointer;"><i class="fal fa-camcorder mr-2" style="font-size: 1.2rem; "></i> Bekijk de productvideo</span>
                                </span>
                                <!-- Modal -->
                                <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-dialog-centered" role="document">
                                        <div class="modal-content">


                                            <div class="modal-body">

                                                <span type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </span>
                                                <!-- 16:9 aspect ratio -->
                                                <div class="embed-responsive embed-responsive-16by9">
                                                    <iframe class="embed-responsive-item" :src="product.video" id="video"  allowscriptaccess="always" allow="autoplay"></iframe>
                                                </div>


                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-12 col-lg-5">
                    <div class="row  pb-4">
                        <div class="col-10">
<!--                            <div class="img-container">-->

<!--                                <img :src="product.featured_image" class="w-100" alt="">-->
<!--                            </div>-->
                            <vueper-slides
                                ref="vueperslides1"
                                :touchable="false"
                                @slide="$refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })"
                                class="no-shadow"
                                :visible-slides="1"
                                :slide-ratio="1 / 1"
                                 bullets-outside 3d
                                :arrows="false"

                               >
                                <vueper-slide  :image="product.featured_image" key="0" @click.native="$refs.vueperslides2.goToSlide(0)" />

                                    <vueper-slide v-for="(image,i) in product.images" :image="image.path" :key="i+1"  @click.native="$refs.vueperslides2.goToSlide(i+1)" />

                            </vueper-slides>

                            <vueper-slides
                                ref="vueperslides2"
                                class="no-shadow py-4"
                                :visible-slides="4"
                                :touchable="false"
                                @slide="$refs.vueperslides1.goToSlide($event.currentSlide.index , { emit: false })"
                                slide-multiple
                                :gap="3"
                                :bullets="false"
                                :slide-ratio="1 / 4"
                                :dragging-distance="200"
                                :breakpoints="{ 800: { visibleSlides: 4, slideMultiple: 4 } }">
                                <vueper-slide  :image="product.featured_image" key="0" @click.native="$refs.vueperslides1.goToSlide(0)" />
                                <vueper-slide v-for="(image, i) in product.images" :image="image.path" :key="i+1"  @click.native="$refs.vueperslides1.goToSlide(i+1)" />

                            </vueper-slides>
                        </div>
                    </div>

                </div>
            </div>
        </div>



        </div>

        <div class="row justify-content-center pb-5" v-if="product && product.related.length > 0">
            <div class="col-12 text-center mb-4">
                <h3>Andere klanten kochten ook:</h3>
            </div>
            <div v-for="(rel_product,index) in product.related"  class="col-6 col-lg-3 mb-5 list-complete-item"  v-bind:key="rel_product.id" v-bind:data-index="index">
                <router-link :to="{name: 'product', params: {category: (rel_product.brand_category.parent) ? rel_product.brand_category.parent.slug : null,  subcategory: rel_product.brand_category.slug, slug: rel_product.slug}}" class="product-item">
                    <div class="img-container">
                        <img :src="rel_product.featured_image" class="w-100" alt="">
                    </div>
                    <div class="product-info">
                        <div class="row align-items-center">
                            <div class="col-12">
                                <strong>{{ rel_product.brand }}</strong><br>
                                {{ rel_product.name}}<br>
                                <span class="price">&euro; {{ formatPrice(rel_product.private_customer_price) }}</span>
                            </div>
                            <!--                                    <div class="col-12">-->
                            <!--                                        <span  class="site-btn" @click.prevent="addToCart(product)" >Toevoegen</span>-->
                            <!--                                    </div>-->
                        </div>
                    </div>
                </router-link>
            </div>
        </div>

    </div>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import SearchBar from './parts/SearchBar';
export default {
    data ()  {
        return {
           qrCode: this.$route.params.code,
            product: null,
            error: '',
            qty: 1,
            message: {
                display: false,
                type: 'success',
                content: ''
            }
        }
    },
     beforeMount() {

        //this.$store.dispatch("allProductsFromDatabase");
         this.$http.get(this.api_url("/product-by-slug"), { params: { slug: this.$route.params.slug}})

            .then((response)=>{
                //context.commit("products",response.data.products) //categories will be run from mutation
                this.product = response.data.product


            })

            .catch(()=>{

                console.log("Error........")

            })


    },
    components: {
        VueperSlides,
        VueperSlide,
        SearchBar
    },
    methods: {
        nltobr(str) {
            return str.replace(/\n/g, '<br />')
        },
        toPage(pageNumber) {

            this.page = pageNumber;
            this.UpdateQueryString('page', this.page)
            window.scrollTo({top: 0,left: 0, behavior: 'smooth'});

        },
        addToCart() {
            this.$store.commit('ADD_Item', {product: this.product, qty: this.qty})
            this.message.content = this.product.brand + ' ' + this.product.name + " is toegevoegd aan het winkelmandje.";
            this.message.display = true;
        },
        formatPrice(value) {

            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")

        },
        clearMessage() {
            this.message.display = false;
            this.message.content = '';
        }
    },
    computed: {
        /* getAllProducts(){ //final output from here
             return this.$store.getters.getProductsFormGetters;
         },*/
        cart() { return this.$store.state.StoreCart },
        cartTotals() { return this.$store.getters.totals }


    },
    watch: {
        '$route.params.slug' : function () {
            this.$http.get(this.api_url("/product-by-slug"), {params: {slug: this.$route.params.slug}})

                .then((response) => {
                    //context.commit("products",response.data.products) //categories will be run from mutation
                    this.product = response.data.product


                })

                .catch(() => {

                    console.log("Error........")

                })
        }
    }


}

</script>
<style lang="scss">
h2, h3 {
    font-family: "Graphik", sans-serif;
}
.price {
    font-size: 24px;
    font-weight: 700;
    color: var(--main-color) !important;
}
.video-container {
    position:relative;
    padding-bottom:56.25%;
    padding-top:30px;
    height:0;
    overflow:hidden;
}

.video-container iframe, .video-container object, .video-container embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}
.img-container {
    border: 1px solid #cfcfcf;
    position: relative;
    padding-bottom: 100%;

img {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    -o-object-fit: contain;
    object-fit: contain;
    background-color: #ffffff;
}

}
.vueperslide {
    position: relative;
    background-color: #ffffff;
    background-size: contain;
    background-repeat: no-repeat;
    &:after {
        content: "";
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border: 1px solid #cfcfcf;
        top: 0;
        left: 0;
        position: absolute;

    }

}
.modal-dialog {
    max-width: 800px;
    margin: 30px auto;
}



.modal-body {
    position:relative;
    padding:0px;
}
.close {
    position:absolute;
    right:-30px;
    top:0;
    z-index:999;
    font-size:2rem;
    font-weight: normal;
    color:#fff;
    opacity:1;
}
.vueperslides__arrow svg {
    vertical-align: middle;
    stroke: currentColor;
    fill: none;
    width: .5em;
    padding: 0em;
    stroke-width: 2;
    box-sizing: border-box;
    color: var(--main-color) !important;
}

.block a {
    color: #695446;
}
button {
    outline: none !important;
}
</style>
