var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-3 mb-5" }, [
    _c(
      "div",
      {
        staticClass: "el-item new-prod-item",
        attrs: {
          role: "group",
          "aria-label": "1 of 10",
          "aria-roledescription": "slide",
          tabindex: "-1",
          "aria-hidden": "false",
        },
      },
      [
        _vm.label && _vm.label !== ""
          ? _c("div", { staticClass: "ribbon" }, [_vm._v("Topproduct")])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "router-link",
          {
            staticClass: "position-relative d-block",
            attrs: {
              to: {
                name: "product",
                params: {
                  category: _vm.category.parent
                    ? _vm.category.parent.slug
                    : null,
                  subcategory: _vm.category.slug,
                  slug: _vm.product.slug,
                },
              },
            },
          },
          [
            _c("img", {
              staticClass: "products-img",
              attrs: { decoding: "async", src: _vm.product.featured_image },
            }),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "products-info" }, [
          _c("span", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.product.name)),
          ]),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "brand" }, [
            _vm._v(_vm._s(_vm.product.brand)),
          ]),
          _c("br"),
          _vm._v(" "),
          _c("span", { staticClass: "description" }, [
            _vm._v(_vm._s(_vm.product.short_description)),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "price" }, [
            _vm._v(
              "€ " + _vm._s(_vm.formatPrice(_vm.product.private_customer_price))
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "products-info-bottom" }, [
          _c("div", { staticClass: "qty-field" }, [
            _c("input", {
              attrs: { type: "number", min: "1", step: "1", name: "qty" },
              domProps: { value: _vm.qty },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "qty-controls" }, [
              _c("span", { staticClass: "plus", on: { click: _vm.qtyPlus } }, [
                _vm._v("+"),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "minus", on: { click: _vm.qtyMin } }, [
                _vm._v("-"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "add-to-cart" }, [
            _c("input", {
              attrs: {
                type: "hidden",
                value:
                  "https://webshop.kanters.be/producten/salonmateriaalolivia-garden/fingerbrush-polar-blue",
                name: "product-url",
              },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "add-to-cart-btn",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.addToCart.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Toevoegen")]
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }